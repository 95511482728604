import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import klineInterval from "./params";

const DATA_COUNT = 500;
const COLLECTION_NAME = "NS_TRADES_70";

interface IXUData {
  trade70: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  trade70: [],
  requested: false,
  loaded: false,
};

export function loadTrade70(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Trade70.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.TRADE_70,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}`)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var trade70Data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          var opendt = doc.data().openDate.split(".");

          trade70Data.push({
            uid: doc.data().symbol + '_' + doc.data().openDate,
            name: "trade70",
            Index: doc.data().index,
            DataPackage: doc.data().dataPackage,
            Date: moment(doc.data().dateTime.toDate()).format("DD.MM.YYYY"),
            Symbol: doc.data().symbol,
            ODate: doc.data().openDate,
            CDate: doc.data().closeDate,
            OPrice: doc.data().openPrice,
            CPrice: doc.data().closePrice,
            PerDay: doc.data().perDay,
            Percent: doc.data().percent,
            Duration: doc.data().closeDate != "" ? moment(doc.data().closeDate, "DD.MM.YYYY").diff(moment(doc.data().openDate, "DD.MM.YYYY"), 'days') : 0,
            dateTime: doc.data().dateTime.toDate(),
            dateOpen: new Date(opendt[2],opendt[1]-1, opendt[0]),
            IncomingSymbol : doc.data().incomingSymbol
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.TRADE_70,
          payload: {
            trade70: trade70Data.sort((a: any, b: any) => { return b.dateTime - a.dateTime || a.dateOpen - b.dateOpen }),
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Trade70(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.TRADE_70:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const trade70Selector = (state: any) => state.Trade70;
