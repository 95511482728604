import moment from "moment";
import { WEB_ACTION } from "./actions";
import { IData } from "./common";
import { EMA } from "technicalindicators";

const DATA_COUNT = 330;
const COLLECTION_NAME = "SENTI_ONE";

interface ISenti1Data {
  sentiment: IData[];
  bist100: IData[];
  bist30: IData[];
  viop30: IData[];
  ema30Sentiment: IData[];
  ema10Sentiment: IData[];
  requested: boolean;
  loaded: boolean;
}
interface ISenti1TradingViewData {
  sentiment: IData[];
  bist100: IData[];
  bist30: IData[];
  viop30: IData[];

  requested: boolean;
  loaded: boolean;
}

const clearEmaValues = (sentimentData: any, emaData: any) => {
  for (var i = 0; i < emaData.length; i++) {
    const sentiData: any = sentimentData[0];
    if (emaData[0].value[0] != sentiData.value[0]) emaData.splice(0, 1);
    else break;
  }
};
function getEmaSentiment(sentimentData: any, period: number) {
  var ema30Sentiment: any[] = [...sentimentData];
  var ema30Values = ema30Sentiment.map((ema: any) => ema.value[1]);
  let ema30Result = EMA.calculate({ period: period, values: ema30Values });
  let ema30UpdatedSentiment: any = [];
  ema30Result.forEach((value: number, idx: number) => {
    ema30UpdatedSentiment.push({
      name: ema30Sentiment[idx].name,
      value: [ema30Sentiment[idx].value[0], value],
      date: ema30Sentiment[idx].date,
    });
  });
  ema30Sentiment = ema30UpdatedSentiment;
  return ema30UpdatedSentiment.reverse();
}
const initialState: ISenti1Data = {
  sentiment: [],
  bist100: [],
  bist30: [],
  viop30: [],
  ema30Sentiment: [],
  ema10Sentiment: [],
  requested: false,
  loaded: false,
};
const initialStateTradingView: ISenti1TradingViewData = {
  sentiment: [],
  bist100: [],
  bist30: [],
  viop30: [],

  requested: false,
  loaded: false,
};

export function loadSenti1TradingView(firebase:any) {
  return async function loadSenti1TradingViewThunk(
    dispatch: any,
    getState: any
  ) {
    const state = getState();
    if (state.Senti1TradingView.requested) return;
    dispatch({
      type: WEB_ACTION.SENTI_1_TRADING_VIEW,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection("SENTI_ONE")
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var sentimentData: Object[] = [];
        var bist100: Object[] = [];
        var bist30: Object[] = [];
        var viop30: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD HH:mm");
          sentimentData.push({
            name: "sentiment",
            value: [dateVal, doc.data().value],
            date: doc.data().dateTime.seconds,
          });
          bist100.push({
            name: "bist100",
            value: [dateVal, doc.data().bist100 ?? 0],
            date: doc.data().dateTime.seconds,
          });
          bist30.push({
            name: "bist30",
            value: [dateVal, doc.data().bist30 ?? 0],
            date: doc.data().dateTime.seconds,
          });
          viop30.push({
            name: "viop30",
            value: [dateVal, doc.data().viop30 ?? 0],
            date: doc.data().dateTime.seconds,
          });
        });

        sentimentData = sentimentData.reverse();
        bist100 = bist100.reverse();
        bist30 = bist30.reverse();
        viop30 = viop30.reverse();
        sentimentData.splice(0, 29);
        bist100.splice(0, 29);
        bist30.splice(0, 29);
        viop30.splice(0, 29);

        dispatch({
          type: WEB_ACTION.SENTI_1_TRADING_VIEW,
          payload: {
            sentiment: sentimentData,
            bist100: bist100,
            bist30: bist30,
            viop30: viop30,

            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export function loadSenti1(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Senti1.requested) return;
    dispatch({
      type: WEB_ACTION.SENTI_1,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var sentimentData: Object[] = [];
        var bist100: Object[] = [];
        var bist30: Object[] = [];
        var viop30: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          if (state.oneMinPeriod !== 1) {
            const dateCheck = new Date(doc.data().dateTime.seconds * 1000);
            const min = dateCheck.getMinutes();
            if (state.oneMinPeriod === 5) {
              if (min % 5 !== 0) return;
            } else if (state.oneMinPeriod === 10) {
              if (min % 10 !== 0) return;
            }
          }
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD HH:mm");
          sentimentData.push({
            name: "sentiment",
            value: [dateVal, doc.data().value],
            date: doc.data().dateTime.seconds,
          });
          bist100.push({
            name: "bist100",
            value: [dateVal, doc.data().bist100 ?? 0],
            date: doc.data().dateTime.seconds,
          });
          bist30.push({
            name: "bist30",
            value: [dateVal, doc.data().bist30 ?? 0],
            date: doc.data().dateTime.seconds,
          });
          viop30.push({
            name: "viop30",
            value: [dateVal, doc.data().viop30 ?? 0],
            date: doc.data().dateTime.seconds,
          });
        });
        const ema30 = getEmaSentiment(sentimentData, 30);
        const ema10 = getEmaSentiment(sentimentData, 10);
        sentimentData = sentimentData.reverse();
        bist100 = bist100.reverse();
        bist30 = bist30.reverse();
        viop30 = viop30.reverse();
        sentimentData.splice(0, 29);
        bist100.splice(0, 29);
        bist30.splice(0, 29);
        viop30.splice(0, 29);

        clearEmaValues(sentimentData, ema30);
        clearEmaValues(sentimentData, ema10);
        dispatch({
          type: WEB_ACTION.SENTI_1,
          payload: {
            sentiment: sentimentData,
            bist100: bist100,
            bist30: bist30,
            viop30: viop30,
            ema30Sentiment: ema30,
            ema10Sentiment: ema10,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Senti1(state = initialState, action: any) {
  switch (action.type) {
    case WEB_ACTION.SENTI_1:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const  Senti1TradingView=(state = initialStateTradingView, action: any)=> {
  switch (action.type) {
    case WEB_ACTION.SENTI_1_TRADING_VIEW:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export const senti1Selector = (state: any) => state.Senti1;
export const senti1TradingViewSelector = (state: any) =>
  state.Senti1TradingView;
