import { Button, Col, Grid, Modal, Row, Select, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadSentimentPower,
  sentimentPowerSelector,
} from "../../../appRedux/reducers/sentiment/sentiment-power";
import { nsUserSelector } from "../../../appRedux/reducers/sentiment/user";
import {
  loadXu30Daily,
  xu30DailySelector,
} from "../../../appRedux/reducers/sentiment/xu030";
import {
  loadXu100Daily,
  xu100DailySelector,
} from "../../../appRedux/reducers/sentiment/xu100";
import {
  dailyInstantSentimentSelector,
  loadDailyInstantSentiment,
} from "../../../appRedux/reducers/web/daily-instant-sentiment";
import { SentimentPower } from "../../../components/SentimentPower";
import { firebaseApp } from "../../../firebase";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import chroma from "chroma-js";
import { THEME_TYPE_DARK } from "../../../constants/ThemeSetting";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import IntlMessages from "../../../util/IntlMessages";
import { SettingOutlined } from "@ant-design/icons";
import LanguageMenu from "../../../components/LanguageMenu/LanguageMenu";
import { isProd, isStaging } from "../../../util/Env";
import "./index.css";

const { Text } = Typography;
const { useBreakpoint } = Grid;

const POWER_SCALE = ["#ff0000", "#ff9933", "#FDDD60", "#39d121"];
const Option = Select.Option;
export const Header = (props: any) => {
  const intl = useIntl();
  const sentimentPowerIndicator = intl.formatMessage({
    id: "header.sentiment_power_indicator",
  });

  const [showPower, setShowPower] = useState(false);
  const [lastPowerDate, setLastPowerDate] = useState<Date | null>(null);
  const dispatch = useDispatch();
  const nsUser = useSelector(nsUserSelector);
  const { themeType } = useSelector(({ settings }: any) => settings);

  useEffect(() => {
    dispatch(loadXu100Daily(firebaseApp));
    dispatch(loadXu30Daily(firebaseApp));
    dispatch(loadDailyInstantSentiment(firebaseApp));
    dispatch(loadSentimentPower(firebaseApp));
  }, [nsUser]);

  const getXUDirection = (xuData: any) => {
    if (!xuData || xuData.length < 2) return "up";
    const last = xuData[xuData.length - 1];
    const previous = xuData[xuData.length - 2];
    if (last.close - previous.close >= 0) return "up";
    else return "down";
  };

  const getSentimentDirection = (data: any) => {
    if (!data || data.length < 2) return "up";
    const last = data[data.length - 1].value[1];
    const previous = data[data.length - 2].value[1];
    if (last - previous >= 0) return "up";
    else return "down";
  };

  const getPowerTimeDiff = (data: any) => {
    if (!data || data.length < 1) return "";
    const last = data[0].dateTime;

    return moment(last).format("HH:mm");
  };

  const xu100Data = useSelector(xu100DailySelector);
  const xu30Data = useSelector(xu30DailySelector);
  const sentimentData = useSelector(dailyInstantSentimentSelector);
  const sentimentPowerData = useSelector(sentimentPowerSelector);
  const xu100Direction = getXUDirection(xu100Data?.xu100Daily);
  const xu30Direction = getXUDirection(xu30Data?.xu30Daily);
  const sentimentDirection = getSentimentDirection(sentimentData?.sentiment);

  useEffect(() => {
    try {
      if (sentimentPowerData?.loaded && sentimentPowerData?.power?.length > 0) {
        if (lastPowerDate != null) {
          if (
            moment(lastPowerDate).isBefore(
              sentimentPowerData?.power?.[0].dateTime
            )
          ) {
            if (
              "Notification" in window &&
              Notification.permission === "granted"
            ) {
              new Notification(`Sentiment Algo`, {
                body: `Güç indikatörü güncellendi.\nYeni: ${sentimentPowerData?.power?.[0]?.value ?? 0
                  }  Eski: ${sentimentPowerData?.power?.[1]?.value ?? 0}`,
              });
            }
            toast.info(
              `Güç indikatörü güncellendi. Yeni: ${sentimentPowerData?.power?.[0]?.value ?? 0
              }  Eski: ${sentimentPowerData?.power?.[1]?.value ?? 0}`,
              {
                autoClose: 5000,
                position: "bottom-right",
                hideProgressBar: true,
                closeOnClick: true,
              }
            );
          }
        }
        setLastPowerDate(sentimentPowerData?.power?.[0]?.dateTime);
      }
    } catch (e) {
      console.error("Power notification error", e);
    }
  }, [sentimentPowerData]);
  useEffect(() => {
    if (
      "Notification" in window &&
      Notification.permission !== "granted" &&
      Notification.permission !== "denied" &&
      localStorage.getItem("notification_permission_requested") !== "true"
    ) {
      localStorage.setItem("notification_permission_requested", "true");
      toast.info("Masaüstü bildirimlerine izin vermek için tıklayınız.", {
        delay: 2000,
        autoClose: 5000,
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: true,
        onClick: () => {
          if ("Notification" in window) Notification.requestPermission();
        },
      });
    }
  }, []);

  const getSentimentPowerValue = (data: any) => {
    if (!data || data.length < 1) return 0;
    return data[0].value;
  };
  const getSentimentPowerDate = (data: any) => {
    if (!data || data.length < 1) return "";
    return moment(data[0].dateTime).format("DD.MM.YYYY HH:mm");
  };

  const handleKlineIntervalChange = (interval: any) => {
    localStorage.setItem("klineInterval", interval);
    window.location.reload();
  };

  const getKlineColor = () => {
    var klineInterval = localStorage.getItem("klineInterval");
    if (klineInterval == "1W") return "gx-text2-info gx-border-white gx-bg-weekly";
    if (klineInterval == "4H") return " gx-text2-orange gx-border-orange";
    if (klineInterval == "4H_v2") return " gx-text2-pink gx-border-pink";
    return "";
  };

  const getKlineTextColor = () => {
    var klineInterval = localStorage.getItem("klineInterval");
    if (klineInterval == "1W") return "gx-text-white";
    if (klineInterval == "4H") return " gx-text-orange";
    if (klineInterval == "4H_v2") return " gx-text-pink";
    return "hidden";
  };

  const screens = useBreakpoint();

  return (
    <Row justify="space-between"
      align="middle"
      className={getKlineColor()}
      style={{
        // height: 68,
        margin: 0,
        padding: screens.xl ? "14px 18px" : "14px 0px",
        borderBottom: "2px solid #e8e8e8",
        fontSize: screens.xxl ? "" : 12.5
      }}>
      <Col style={{ width: screens.xl ? "" : "100%" }}>
        <Row gutter={[16, 16]} align="middle" justify={screens.xl ? "start" : "space-around"} style={{ margin: 0 }}>
          <Col style={{ textAlign: screens.lg ? "start" : "center" }}>
            {screens.lg && <i
              className={`icon icon-menu-${xu100Direction} gx-fs-md gx-chart-${xu100Direction}`}
            />}
            <Text className={"" + getKlineTextColor()}
              style={{ display: screens.lg ? "unset" : "grid", color: "inherit", padding: screens.xl ? "0 2px" : "" }}>
              {screens.lg ? "BIST100: " : "BIST100"}
            </Text>
            {!screens.lg && <i
              className={`icon icon-menu-${xu100Direction} gx-fs-md gx-chart-${xu100Direction}`}
            />}
            <Text className={`gx-chart-${xu100Direction}`}>
              {xu100Data?.xu100Daily?.length > 0
                ? xu100Data?.xu100Daily[
                  xu100Data?.xu100Daily.length - 1
                ].close.toFixed(2)
                : 0}
            </Text>
          </Col>
          <Col style={{ textAlign: screens.lg ? "start" : "center" }}>
            {screens.lg && <i
              className={`icon icon-menu-${xu30Direction} gx-fs-md gx-chart-${xu30Direction}`}
            />}
            <Text className={"" + getKlineTextColor()}
              style={{ display: screens.lg ? "unset" : "grid", color: "inherit", padding: screens.xl ? "0 2px" : "" }}>
              {screens.lg ? "BIST30: " : "BIST30"}
            </Text>
            {!screens.lg && <i
              className={`icon icon-menu-${xu30Direction} gx-fs-md gx-chart-${xu30Direction}`}
            />}
            <Text className={`gx-chart-${xu30Direction}`}>
              {xu30Data?.xu30Daily?.length > 0
                ? xu30Data?.xu30Daily[
                  xu30Data?.xu30Daily.length - 1
                ].close.toFixed(2)
                : 0}
            </Text>
          </Col>
          <Col style={{ textAlign: screens.lg ? "start" : "center" }}>
            {screens.lg && <i
              className={`icon icon-menu-${sentimentDirection} gx-fs-md gx-chart-${sentimentDirection}`}
            />}
            <Text className={"" + getKlineTextColor()}
              style={{ display: screens.lg ? "unset" : "grid", color: "inherit", padding: screens.xl ? "0 2px" : "" }}>
              {screens.lg ? <><IntlMessages id="main.sentiment" />:{" "}</> : <IntlMessages id="main.sentiment" />}
            </Text>
            {!screens.lg && <i
              className={`icon icon-menu-${sentimentDirection} gx-fs-md gx-chart-${sentimentDirection}`}
            />}
            <Text className={`gx-chart-${sentimentDirection}`}>
              {sentimentData?.sentiment?.length > 0
                ? sentimentData?.sentiment[
                  sentimentData?.sentiment.length - 1
                ].value[1].toFixed(2)
                : 0}
            </Text>
          </Col>
          <Col style={{ textAlign: screens.lg ? "start" : "center" }}>
            <Row align="middle" style={{ cursor: "pointer", margin: 0 }}>
              {screens.lg ?
                <>
                  <Text className={getKlineTextColor()} style={{ paddingRight: screens.xl ? 4 : 0, color: "inherit" }}>
                    <IntlMessages id="header.sentiment_power" />{"  "}
                  </Text>
                  <div style={{ width: "24px", height: "24px" }} onClick={() => setShowPower(true)}>
                    <CircularProgressbar
                      styles={buildStyles({
                        textSize: "44px",
                        textColor: themeType === THEME_TYPE_DARK ? "#fff" : "#000",
                        trailColor:
                          themeType === THEME_TYPE_DARK ? "#4e5b66" : "#f8f8f8",
                        pathColor: chroma
                          .scale(POWER_SCALE)(
                            getSentimentPowerValue(sentimentPowerData?.power) / 100
                          )
                          .toString(),
                      })}
                      value={getSentimentPowerValue(sentimentPowerData?.power)}
                      maxValue={100}
                      text={getSentimentPowerValue(sentimentPowerData?.power)}
                    />
                  </div>
                  <Text className={getKlineTextColor()} style={{
                    fontSize: "12px",
                    color: "#777",
                    paddingLeft: "5px",
                  }}>
                    {" "}
                    {getPowerTimeDiff(sentimentPowerData?.power)}
                  </Text>
                </> :
                <>
                  <Text className={getKlineTextColor()}
                    style={{
                      display: "grid",
                      width: "100%",
                      color: "inherit"
                    }}>
                    <IntlMessages id="header.sentiment_power_mobile" />
                  </Text>
                  <Text
                    style={{
                      color: chroma
                        .scale(POWER_SCALE)(
                          getSentimentPowerValue(sentimentPowerData?.power) / 100
                        )
                        .toString(),
                      width: "100%",
                      textAlign: "center"
                    }}
                  >
                    {getSentimentPowerValue(sentimentPowerData?.power)}
                  </Text>
                </>
              }
            </Row>
          </Col>
          {screens.xl ? <> </> :
            screens.lg ?
            <Row gutter={[8, 16]}>
                <Col>
                  <i className={`icon icon-circle gx-fs-md gx-mr-2 ${getKlineTextColor()}`} />
                  <Select
                    defaultValue={localStorage.getItem("klineInterval") ?? ""}
                    onChange={handleKlineIntervalChange}
                  >
                    <Option value="">{screens.xl ? <IntlMessages id="header.interval_daily" /> : "Günlük"}</Option>
                    <Option value="1W">{screens.xl ? <IntlMessages id="header.interval_weekly" /> : "Haftalık"}</Option>
                    {(nsUser.cooperate && <Option value="4H">  <IntlMessages id="header.interval_4h_slow" /> </Option>)}
                    {(nsUser.cooperate && <Option value="4H_v2"> <IntlMessages id="header.interval_4h_fast" /> </Option>)}
                  </Select>
                </Col>
                <Col>
                  <LanguageMenu setShowsSettings={props.setShowsSettings} />
                </Col>
                <Col>
                  <Button style={{ margin: 0, padding: 0 }} shape="circle" icon={<SettingOutlined
                    onClick={() => {
                      props.setShowsSettings(true);
                    }}
                    style={{ fontSize: 17 }}
                  />} />
                </Col>
              </Row>
              :
              <Col>
                <SettingOutlined style={{ fontSize: 17 }}
                  onClick={() => {
                    props.setShowsSettings(true);
                  }}
                />
              </Col>
          }
        </Row>
      </Col>
      {screens.xl &&
        <Col>
          <Row gutter={[16, 0]} align="middle" justify="end" style={{ margin: 0 }}>
            <Col>
              <i className={`icon icon-circle gx-fs-md gx-mr-2 ${getKlineTextColor()}`} />
              <Select
                defaultValue={localStorage.getItem("klineInterval") ?? ""}
                onChange={handleKlineIntervalChange}
              >
                <Option value="">{screens.xl ? <IntlMessages id="header.interval_daily" /> : "Günlük"}</Option>
                <Option value="1W">{screens.xl ? <IntlMessages id="header.interval_weekly" /> : "Haftalık"}</Option>
                {(nsUser.cooperate && <Option value="4H">  <IntlMessages id="header.interval_4h_slow" /> </Option>)}
                {(nsUser.cooperate && <Option value="4H_v2"> <IntlMessages id="header.interval_4h_fast" /> </Option>)}
              </Select>
            </Col>
            <Col>
              <LanguageMenu setShowsSettings={props.setShowsSettings} />
            </Col>
            <Col>
              <Button style={{ margin: 0, padding: 0 }} shape="circle" icon={<SettingOutlined
                onClick={() => {
                  props.setShowsSettings(true);
                }}
                style={{ fontSize: 17 }}
              />} />
            </Col>
          </Row>
        </Col>
      }
      {showPower && (
        <Modal
          transitionName=""
          title={`${sentimentPowerIndicator} (${getSentimentPowerDate(
            sentimentPowerData?.power
          )})`}
          className="chart-container"
          open={showPower}
          onCancel={() => setShowPower(false)}
          footer={null}
          bodyStyle={{ minHeight: 500 }}
        >
          <SentimentPower
            power={
              sentimentPowerData?.power?.length > 0
                ? sentimentPowerData?.power[0].value
                : 0
            }
          />
        </Modal>
      )}
    </Row>
  );
};
