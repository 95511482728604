import { Card } from "antd";

export const PaymentFail = (props: any) => {
  return (
    <Card>
      <div
        style={{
          padding: 30,
          display: "flex",
          flexDirection: "column",
          gap: 30,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ fontWeight: "bold", fontSize: 28 }}>Hay Aksi!</div>
        <img width={64} height={64} src="/assets/images/fail.png" />
        <div style={{
          padding: 30,
          display: "flex",
          flexDirection: "column",
          gap: 10,
          alignItems: "center",
          justifyContent: "center",
        }}>
          <div>Ödemeniz sırasında hata oluştu.</div>
          <div>
            Tekrar denemek için <a href="/payment">tıklayın</a>.
          </div>
        </div>
      </div>
    </Card>
  );
};
