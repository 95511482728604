import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import klineInterval from "./params";

const COLLECTION_NAME = "NS_TIMELINE_70";

interface IXUData {
  data: IData[];
  data2: IData[];
  requested: boolean;
  loaded: boolean;
  lastUpdate: number;
}

const initialState: IXUData = {
  data: [],
  data2: [],
  requested: false,
  loaded: false,
  lastUpdate: 0
};

export function loadTimeline70(firebase: any, period: number) {
  if (period < 0 || ![0, 10, 30, 99].includes(period)) {
    console.log("Invalid period for line 30. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (new Date().getTime() - state.Timeline70.lastUpdate < 60 * 1000) return;

    dispatch({
      type: SENTIMENT_ACTION.TIMELINE_70,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}_P${period}`)
      .orderBy("dateTime", "desc")
      .limit(250)
      .onSnapshot((querySnapshot: any) => {
        var data: any = [];

        querySnapshot.forEach(function (doc: any) {
          data.push({
            seconds: doc.data().dateTime.seconds,
            IsValid: true,
            Signal: doc.data().signal,
            Index: 70,
            Symbols: doc.data().symbols,
            Date: moment
              .unix(doc.data().dateTime.seconds)
              .format("DD.MM.yy"),
            Time: moment
              .unix(doc.data().dateTime.seconds)
              .format("HH:mm")
          });
        });

        var allSignals: Object[] = [];
        let dayData = data.filter((x: any) => { return x.Date == data[0].Date });
        for (let i = 0; i < 10; i++) {
          let signals = dayData.filter((x: any) => { return x.Signal == i });
          let prev = new Array();

          for (let i = signals.length - 1; i >= 0; i--) {
            let element: any = signals[i];

            if (prev == element.Symbols) continue;

            element.Symbols.forEach((symbol: string) => {
              if (!prev.includes(symbol)) {
                allSignals.push({
                  seconds: element.seconds,
                  IsValid: true,
                  Signal: element.Signal,
                  Index: 70,
                  Symbol: symbol,
                  Date: element.Date,
                  Time: element.Time,
                });
              }
            });

            prev.forEach((symbol: string) => {
              if (!element.Symbols.includes(symbol)) {
                allSignals.push({
                  seconds: element.seconds,
                  IsValid: false,
                  Signal: element.Signal,
                  Index: 70,
                  Symbol: symbol,
                  Date: element.Date,
                  Time: element.Time,
                });
              }
            });
            prev = element.Symbols;
          }
        }


        dispatch({
          type: SENTIMENT_ACTION.TIMELINE_70,
          payload: {
            data: data,
            data2: allSignals.sort(function (a: any, b: any) { return b.seconds - a.seconds }),
            loaded: true,
            requested: true,
            lastUpdate: new Date().getTime()
          },
        });
      });
  };
}

export default function Timeline70(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.TIMELINE_70:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const timeline70Selector = (state: any) => state.Timeline70;
