import { Alert, Button, Card, Col, Form, Input, Row, Select } from "antd";
import { COUNTRIES } from "./countries";
import axios from "axios";
import { auth } from "../../firebase";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import queryString from "qs";
import { usePayment } from "./PaymentContext";

export const CustomerForm = (props: any) => {
  const router = useHistory();

  const [error, setError] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [newPackageDetails, setNewPackageDetails] = useState<any>(null);
  const { search } = useLocation();
  const { details, setDetails } = usePayment();
  const [country, setCountry] = useState("Türkiye");

  const [customerType, setCustomerType] = useState(
    details?.address?.customer_type ?? "personal"
  );
  const [form] = Form.useForm();

  useEffect(() => {
    if (details?.address) {
      if (
        details?.address?.customer_type !== customerType &&
        ["personal", "company"].includes(details?.address?.customer_type)
      )
        setCustomerType(details?.address?.customer_type);
    }
  }, [details]);

  useEffect(() => {
    const values = queryString.parse(search, { ignoreQueryPrefix: true });
    setNewPackageDetails(values);
  }, [search]);

  const customerFormCompleted = async (data: any) => {
    const token = await auth.currentUser?.getIdToken();
    setError(null);
    setLoading(true);
    axios
      .post(
        "https://europe-west1-algo-senti.cloudfunctions.net/payment-token",
        {
          name: data.name,
          surname: data.surname,
          phone: data.phone,
          tc: data.tc,
          country: data.country,
          state: data.state,
          city: data.city,
          street: data.street,
          company: data.company,
          postal_code: data.postal_code,
          packageId: newPackageDetails["new-package-id"],
          duration: newPackageDetails["duration"],
          code: newPackageDetails["code"] ?? undefined,
          tax_office: data.tax_office,
          tax_no: data.tax_no,
          contact: data.contact,
          customer_type: customerType,
        },
        {
          headers: {
            "x-app-uid": token,
          },
        }
      )
      .then((res) => {
        router.push(`/payment/complete?token=${res.data.token}`);
      })
      .catch((err) => {
        console.error(err);

        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="customer-form">
      <Card className="fadein-element">
        <div className="payment-header">
          <span>Fatura Bilgileri</span>
          <Select
            style={{ width: 200 }}
            defaultValue={"personal"}
            value={customerType}
            onChange={(e) => setCustomerType(e)}
            options={[
              { value: "personal", label: "Bireysel" },
              { value: "company", label: "Kurumsal" },
            ]}
          ></Select>
        </div>
        {error && (
          <Alert message={"Hata: " + error.response?.data} type="error" />
        )}
        <Form
          form={form}
          labelAlign="left"
          labelCol={{ lg: 6 }}
          wrapperCol={{ lg: 18 }}
          onFinish={customerFormCompleted}
        >
          <Row>
            <Col lg={12} xs={24}>
              {customerType === "company" && (
                <Form.Item
                  label={"Şirket Ünvanı"}
                  name="company"
                  required
                  rules={[{ required: true, message: "Şirket ünvanı gerekli" }]}
                  initialValue={details?.address?.company ?? ""}
                >
                  <Input></Input>
                </Form.Item>
              )}
              {customerType === "company" && (
                <Form.Item
                  label={"Vergi No"}
                  name="tax_no"
                  required
                  rules={[{ required: true, message: "Vergi No gerekli" }]}
                  initialValue={details?.address?.tax_no ?? ""}
                >
                  <Input></Input>
                </Form.Item>
              )}
              {customerType === "company" && (
                <Form.Item
                  label={"Vergi Dairesi"}
                  name="tax_office"
                  required
                  rules={[{ required: true, message: "Vergi Dairesi gerekli" }]}
                  initialValue={details?.address?.tax_office ?? ""}
                >
                  <Input></Input>
                </Form.Item>
              )}
              {customerType === "company" && (
                <Form.Item
                  label={"İrtibat Ad/Soyad"}
                  name="contact"
                  initialValue={details?.address?.contact ?? ""}
                >
                  <Input></Input>
                </Form.Item>
              )}
              {customerType === "personal" && (
                <Form.Item
                  label={"Ad"}
                  name="name"
                  required
                  rules={[{ required: true, message: "Ad gerekli" }]}
                  initialValue={details?.address?.name ?? ""}
                >
                  <Input></Input>
                </Form.Item>
              )}
              {customerType === "personal" && (
                <Form.Item
                  label={"Soyad"}
                  name="surname"
                  required
                  rules={[{ required: true, message: "Soyad gerekli" }]}
                  initialValue={details?.address?.surname ?? ""}
                >
                  <Input></Input>
                </Form.Item>
              )}
              {customerType === "personal" && (
                <Form.Item
                  label={"Kimlik No"}
                  name="tc"
                  required
                  rules={[{ required: true, message: "Kimlik No gerekli" }]}
                  initialValue={details?.address?.tc ?? ""}
                >
                  <Input></Input>
                </Form.Item>
              )}
              <Form.Item
                label={"Telefon"}
                name="phone"
                required
                rules={[{ required: true, message: "Telefon gerekli" }]}
                initialValue={details?.address?.phone ?? ""}
              >
                <Input></Input>
              </Form.Item>
              {customerType === "personal" && (
                <Form.Item
                  label={"Ülke"}
                  name="country"
                  required
                  rules={[{ required: true, message: "Ülke gerekli" }]}
                  initialValue={
                    details?.address?.country &&
                    COUNTRIES.includes(details?.address?.country)
                      ? details?.address?.country
                      : "Türkiye"
                  }
                >
                  <Select
                    onChange={(e) => {
                      form.setFieldValue("state", "");
                      setCountry(e);
                    }}
                    options={COUNTRIES}
                  ></Select>
                </Form.Item>
              )}
            </Col>

            <Col lg={12} xs={24}>
              {customerType === "company" && (
                <Form.Item
                  label={"Ülke"}
                  name="country"
                  required
                  rules={[{ required: true, message: "Ülke gerekli" }]}
                  initialValue={
                    details?.address?.country &&
                    COUNTRIES.includes(details?.address?.country)
                      ? details?.address?.country
                      : "Türkiye"
                  }
                >
                  <Select
                    onChange={(e) => {
                      form.setFieldValue("state", "");
                      setCountry(e);
                    }}
                    options={COUNTRIES}
                  ></Select>
                </Form.Item>
              )}
              {country !== "Türkiye" && (
                <Form.Item
                  label={"Şehir"}
                  name="state"
                  required
                  rules={[{ required: true, message: "Şehir gerekli" }]}
                  initialValue={details?.address?.state ?? ""}
                >
                  <Input></Input>
                </Form.Item>
              )}
              {country === "Türkiye" && (
                <Form.Item
                  name="state"
                  label={"Şehir"}
                  required
                  rules={[{ required: true, message: "Şehir gerekli" }]}
                  initialValue={"İSTANBUL"}
                >
                  <Select>
                    <option value="ADANA">ADANA</option>
                    <option value="ADIYAMAN">ADIYAMAN</option>
                    <option value="AFYONKARAHİSAR">AFYONKARAHİSAR</option>
                    <option value="AĞRI">AĞRI</option>
                    <option value="AKSARAY">AKSARAY</option>
                    <option value="AMASYA">AMASYA</option>
                    <option value="ANKARA">ANKARA</option>
                    <option value="ANTALYA">ANTALYA</option>
                    <option value="ARDAHAN">ARDAHAN</option>
                    <option value="ARTVİN">ARTVİN</option>
                    <option value="AYDIN">AYDIN</option>
                    <option value="BALIKESİR">BALIKESİR</option>
                    <option value="BARTIN">BARTIN</option>
                    <option value="BATMAN">BATMAN</option>
                    <option value="BAYBURT">BAYBURT</option>
                    <option value="BİLECİK">BİLECİK</option>
                    <option value="BİNGÖL">BİNGÖL</option>
                    <option value="BİTLİS">BİTLİS</option>
                    <option value="BOLU">BOLU</option>
                    <option value="BURDUR">BURDUR</option>
                    <option value="BURSA">BURSA</option>
                    <option value="ÇANAKKALE">ÇANAKKALE</option>
                    <option value="ÇANKIRI">ÇANKIRI</option>
                    <option value="ÇORUM">ÇORUM</option>
                    <option value="DENİZLİ">DENİZLİ</option>
                    <option value="DİYARBAKIR">DİYARBAKIR</option>
                    <option value="DÜZCE">DÜZCE</option>
                    <option value="EDİRNE">EDİRNE</option>
                    <option value="ELAZIĞ">ELAZIĞ</option>
                    <option value="ERZİNCAN">ERZİNCAN</option>
                    <option value="ERZURUM">ERZURUM</option>
                    <option value="ESKİŞEHİR">ESKİŞEHİR</option>
                    <option value="GAZİANTEP">GAZİANTEP</option>
                    <option value="GİRESUN">GİRESUN</option>
                    <option value="GÜMÜŞHANE">GÜMÜŞHANE</option>
                    <option value="HAKKARİ">HAKKARİ</option>
                    <option value="HATAY">HATAY</option>
                    <option value="IĞDIR">IĞDIR</option>
                    <option value="ISPARTA">ISPARTA</option>
                    <option value="İSTANBUL">İSTANBUL</option>
                    <option value="İZMİR">İZMİR</option>
                    <option value="KAHRAMANMARAŞ">KAHRAMANMARAŞ</option>
                    <option value="KARABÜK">KARABÜK</option>
                    <option value="KARAMAN">KARAMAN</option>
                    <option value="KARS">KARS</option>
                    <option value="KASTAMONU">KASTAMONU</option>
                    <option value="KAYSERİ">KAYSERİ</option>
                    <option value="KIRIKKALE">KIRIKKALE</option>
                    <option value="KIRKLARELİ">KIRKLARELİ</option>
                    <option value="KIRŞEHİR">KIRŞEHİR</option>
                    <option value="KİLİS">KİLİS</option>
                    <option value="KOCAELİ">KOCAELİ</option>
                    <option value="KONYA">KONYA</option>
                    <option value="KÜTAHYA">KÜTAHYA</option>
                    <option value="MALATYA">MALATYA</option>
                    <option value="MANİSA">MANİSA</option>
                    <option value="MARDİN">MARDİN</option>
                    <option value="MERSİN(İÇEL)">MERSİN(İÇEL)</option>
                    <option value="MUĞLA">MUĞLA</option>
                    <option value="MUŞ">MUŞ</option>
                    <option value="NEVŞEHİR">NEVŞEHİR</option>
                    <option value="NİĞDE">NİĞDE</option>
                    <option value="ORDU">ORDU</option>
                    <option value="OSMANİYE">OSMANİYE</option>
                    <option value="RİZE">RİZE</option>
                    <option value="SAKARYA">SAKARYA</option>
                    <option value="SAMSUN">SAMSUN</option>
                    <option value="SİİRT">SİİRT</option>
                    <option value="SİNOP">SİNOP</option>
                    <option value="SİVAS">SİVAS</option>
                    <option value="ŞANLIURFA">ŞANLIURFA</option>
                    <option value="ŞIRNAK">ŞIRNAK</option>
                    <option value="TEKİRDAĞ">TEKİRDAĞ</option>
                    <option value="TOKAT">TOKAT</option>
                    <option value="TRABZON">TRABZON</option>
                    <option value="TUNCELİ">TUNCELİ</option>
                    <option value="UŞAK">UŞAK</option>
                    <option value="VAN">VAN</option>
                    <option value="YALOVA">YALOVA</option>
                    <option value="YOZGAT">YOZGAT</option>
                    <option value="ZONGULDAK">ZONGULDAK</option>
                  </Select>
                </Form.Item>
              )}
              <Form.Item
                label={"İlçe/Semt"}
                name="city"
                required
                rules={[{ required: true, message: "İlçe gerekli" }]}
                initialValue={details?.address?.city ?? ""}
              >
                <Input></Input>
              </Form.Item>
              <Form.Item
                label={"Sokak"}
                name="street"
                required
                rules={[{ required: true, message: "Sokak gerekli" }]}
                initialValue={details?.address?.street ?? ""}
              >
                <Input></Input>
              </Form.Item>
              <Form.Item
                label={"Posta Kodu"}
                name="postal_code"
                required
                rules={[{ required: true, message: "Posta Kodu gerekli" }]}
                initialValue={details?.address?.postal_code ?? ""}
              >
                <Input></Input>
              </Form.Item>
            </Col>
          </Row>
          <div style={{ display: "flex", gap: 10, justifyContent: "end" }}>
            <Button
              onClick={() => {
                router.push("/payment/");
              }}
              disabled={loading}
              style={{ float: "right" }}
              type="ghost"
            >
              Geri
            </Button>
            <Button
              disabled={loading}
              loading={loading}
              htmlType="submit"
              style={{ float: "right" }}
              type="primary"
            >
              Devam et
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};
