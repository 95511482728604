import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 1000;
const COLLECTION_NAME = "NS_XU030_DAILY";

interface IBalanceData {
  balance: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IBalanceData = {
  balance: [],
  requested: false,
  loaded: false,
};

export function loadxu030DailyBalance(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Xu030DailyBalance.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.XU030_DAILY_BALANCE,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var balanceData: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          let date: Date = doc.data().dateTime.toDate();
          date.setHours(0, 0, 0, 0);
          balanceData.push({
            name: "balance",
            balance: doc.data().close,
            dateTime: date,
          });

        });


        balanceData = balanceData.reverse();
        dispatch({
          type: SENTIMENT_ACTION.XU030_DAILY_BALANCE,
          payload: {
            balance: balanceData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Xu030DailyBalance(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.XU030_DAILY_BALANCE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const xu030DailyBalanceSelector = (state: any) =>
  state.Xu030DailyBalance;
