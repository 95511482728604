export enum WEB_ACTION {
  DAILY_SENTIMENT = "DAILY_SENTIMENT",
  DAILY_INSTANT_SENTIMENT = "DAILY_INSTANT_SENTIMENT",
  HOURLY_SENTIMENT = "HOURLY_SENTIMENT",
  OSILATOR = "OSILATOR",
  SENTI_1 = "SENTI-1",
  SENTI_1_TRADING_VIEW="SENTI-1-TRADING-VIEW",
  SENTI_1_SNAPSHOT = "SENTI-1-SNAPSHOT",
  SENTI_1_BIST100="SENTI_1_BIST100",
  SENTI_15 = "SENTI-15",
  USER = "USER",
  MESSAGES="MESSAGES",
  DAILY_SENTIMENT_LAST = "DAILY_SENTIMENT_LAST",
}
