import axios from "axios";
const key = "salgo_symbols_040724";
export const setSymbols = () => {
    axios({
        url: 'https://europe-west1-algo-senti.cloudfunctions.net/zephlex-symbols',
        method: 'get',
        headers: {
            "x-api-key": "3ceb2ab173458a6e75df81c01dac15e461db173f6f4e9a63556221324b362afa",
        }
    })
        .then(({ data }) => {
            localStorage.setItem(key, JSON.stringify(data.symbols));
            console.log("SYMBOLS LOADED");
        })
        .catch(function (error) {
            console.log("SYMBOLS ERROR");
        });
};

const symbols = [
    { key: "A1CAP", val: 200 },
    { key: "ADEL", val: 200 },
    { key: "ADESE", val: 200 },
    { key: "ADGYO", val: 200 },
    { key: "AEFES", val: 70 },
    { key: "AFYON", val: 200 },
    { key: "AGESA", val: 200 },
    { key: "AGHOL", val: 70 },
    { key: "AGROT", val: 70 },
    { key: "AHGAZ", val: 200 },
    { key: "AKBNK", val: 30 },
    { key: "AKCNS", val: 200 },
    { key: "AKFGY", val: 70 },
    { key: "AKFYE", val: 70 },
    { key: "AKGRT", val: 200 },
    { key: "AKSA", val: 70 },
    { key: "AKSEN", val: 70 },
    { key: "AKSGY", val: 200 },
    { key: "ALARK", val: 30 },
    { key: "ALBRK", val: 200 },
    { key: "ALCAR", val: 200 },
    { key: "ALCTL", val: 200 },
    { key: "ALFAS", val: 70 },
    { key: "ALGYO", val: 200 },
    { key: "ALKIM", val: 200 },
    //{ key: "ALTNY", val: 200 },
    { key: "ALVES", val: 200 },
    { key: "ANHYT", val: 200 },
    { key: "ANSGR", val: 200 },
    { key: "ARASE", val: 200 },
    { key: "ARCLK", val: 70 },
    { key: "ARDYZ", val: 70 },
    { key: "ARENA", val: 200 },
    { key: "ASELS", val: 30 },
    { key: "ASGYO", val: 200 },
    { key: "ASTOR", val: 30 },
    { key: "ASUZU", val: 200 },
    { key: "ATAKP", val: 200 },
    { key: "ATATP", val: 200 },
    { key: "AVPGY", val: 200 },
    { key: "AYDEM", val: 200 },
    { key: "AYGAZ", val: 200 },
    { key: "AZTEK", val: 200 },
    { key: "BAGFS", val: 200 },
    { key: "BASGZ", val: 200 },
    { key: "BERA", val: 70 },
    { key: "BFREN", val: 70 },
    { key: "BIENY", val: 200 },
    { key: "BIGCH", val: 200 },
    { key: "BIMAS", val: 30 },
    { key: "BINHO", val: 70 },
    { key: "BIOEN", val: 200 },
    { key: "BIZIM", val: 200 },
    { key: "BOBET", val: 200 },
    { key: "BORLS", val: 200 },
    { key: "BORSK", val: 200 },
    { key: "BRISA", val: 200 },
    { key: "BRKVY", val: 200 },
    { key: "BRLSM", val: 200 },
    { key: "BRSAN", val: 30 },
    { key: "BRYAT", val: 70 },
    { key: "BSOKE", val: 200 },
    { key: "BTCIM", val: 70 },
    { key: "BUCIM", val: 200 },
    { key: "CANTE", val: 70 },
    { key: "CATES", val: 200 },
    { key: "CCOLA", val: 70 },
    { key: "CEMAS", val: 200 },
    { key: "CEMTS", val: 200 },
    { key: "CIMSA", val: 70 },
    { key: "CLEBI", val: 200 },
    { key: "CMBTN", val: 200 },
    { key: "CRFSA", val: 200 },
    { key: "CVKMD", val: 200 },
    { key: "CWENE", val: 70 },
    { key: "DAPGM", val: 200 },
    { key: "DESA", val: 200 },
    { key: "DEVA", val: 200 },
    { key: "DGNMO", val: 200 },
    { key: "DOAS", val: 30 },
    { key: "DOHOL", val: 70 },
    { key: "EBEBK", val: 200 },
    { key: "ECILC", val: 70 },
    { key: "ECZYT", val: 70 },
    { key: "EGEEN", val: 70 },
    { key: "EGGUB", val: 200 },
    { key: "EGPRO", val: 200 },
    { key: "EKGYO", val: 30 },
    { key: "EKOS", val: 200 },
    { key: "EKSUN", val: 200 },
    { key: "ELITE", val: 200 },
    { key: "EMKEL", val: 200 },
    { key: "ENERY", val: 70 },
    { key: "ENJSA", val: 70 },
    { key: "ENKAI", val: 30 },
    //{ key: "ENTRA", val: 200 },
    { key: "ERBOS", val: 200 },
    { key: "ERCB", val: 200 },
    { key: "EREGL", val: 30 },
    { key: "ESCAR", val: 200 },
    { key: "ESCOM", val: 200 },
    { key: "EUPWR", val: 70 },
    { key: "EUREN", val: 70 },
    { key: "FENER", val: 200 },
    { key: "FORTE", val: 200 },
    { key: "FROTO", val: 30 },
    { key: "FZLGY", val: 200 },
    { key: "GARAN", val: 30 },
    { key: "GEDIK", val: 200 },
    { key: "GENIL", val: 200 },
    { key: "GEREL", val: 200 },
    { key: "GESAN", val: 70 },
    { key: "GIPTA", val: 200 },
    { key: "GLCVY", val: 200 },
    { key: "GLYHO", val: 200 },
    { key: "GOKNR", val: 200 },
    { key: "GOLTS", val: 70 },
    { key: "GOODY", val: 200 },
    { key: "GOZDE", val: 200 },
    { key: "GRSEL", val: 200 },
    { key: "GRTRK", val: 200 },
    { key: "GSDHO", val: 200 },
    { key: "GSRAY", val: 200 },
    { key: "GUBRF", val: 30 },
    { key: "GWIND", val: 200 },
    { key: "HALKB", val: 70 },
    { key: "HATSN", val: 200 },
    { key: "HEDEF", val: 200 },
    { key: "HEKTS", val: 30 },
    { key: "HLGYO", val: 200 },
    //{ key: "HOROZ", val: 200 },
    //{ key: "HRKET", val: 200 },
    { key: "HTTBT", val: 200 },
    { key: "HUNER", val: 200 },
    { key: "IEYHO", val: 200 },
    { key: "IHAAS", val: 200 },
    { key: "IMASM", val: 200 },
    { key: "INDES", val: 200 },
    { key: "INFO", val: 200 },
    { key: "INVEO", val: 200 },
    { key: "INVES", val: 200 },
    { key: "IPEKE", val: 200 },
    { key: "ISCTR", val: 30 },
    { key: "ISDMR", val: 200 },
    { key: "ISFIN", val: 200 },
    { key: "ISGYO", val: 70 },
    { key: "ISMEN", val: 70 },
    { key: "ISSEN", val: 200 },
    { key: "IZENR", val: 70 },
    { key: "IZMDC", val: 200 },
    { key: "JANTS", val: 200 },
    { key: "KAREL", val: 200 },
    { key: "KARSN", val: 200 },
    { key: "KARTN", val: 200 },
    { key: "KATMR", val: 200 },
    { key: "KAYSE", val: 70 },
    { key: "KBORU", val: 200 },
    { key: "KCAER", val: 70 },
    { key: "KCHOL", val: 30 },
    { key: "KERVT", val: 200 },
    { key: "KLGYO", val: 200 },
    { key: "KLKIM", val: 200 },
    { key: "KLRHO", val: 200 },
    { key: "KLSER", val: 70 },
    { key: "KMPUR", val: 200 },
    //{ key: "KOCMT", val: 200 },
    { key: "KONKA", val: 200 },
    { key: "KONTR", val: 30 },
    { key: "KONYA", val: 70 },
    { key: "KOPOL", val: 200 },
    { key: "KORDS", val: 200 },
    //{ key: "KOTON", val: 200 },
    { key: "KOZAA", val: 70 },
    { key: "KOZAL", val: 30 },
    { key: "KRDMA", val: 200 },
    { key: "KRDMB", val: 200 },
    { key: "KRDMD", val: 30 },
    { key: "KRVGD", val: 200 },
    { key: "KTLEV", val: 70 },
    { key: "KTSKR", val: 200 },
    { key: "KUYAS", val: 200 },
    { key: "KZBGY", val: 200 },
    { key: "KZGYO", val: 200 },
    { key: "LIDER", val: 200 },
    //{ key: "LILAK", val: 200 },
    { key: "LMKDC", val: 70 },
    { key: "LOGO", val: 200 },
    { key: "LRSHO", val: 200 },
    { key: "MAALT", val: 200 },
    { key: "MAGEN", val: 200 },
    { key: "MAVI", val: 70 },
    { key: "MEDTR", val: 200 },
    { key: "MEGMT", val: 200 },
    { key: "MGROS", val: 70 },
    { key: "MIATK", val: 70 },
    { key: "MIPAZ", val: 200 },
    { key: "MOBTL", val: 200 },
    { key: "MOGAN", val: 200 },
    { key: "MPARK", val: 200 },
    { key: "MRGYO", val: 200 },
    { key: "NATEN", val: 200 },
    { key: "NETAS", val: 200 },
    { key: "NTGAZ", val: 200 },
    { key: "NTHOL", val: 200 },
    { key: "NUHCM", val: 200 },
    { key: "OBAMS", val: 70 },
    { key: "ODAS", val: 70 },
    //{ key: "ODINE", val: 200 },
    { key: "ORGE", val: 200 },
    { key: "OTKAR", val: 70 },
    { key: "OYAKC", val: 30 },
    { key: "OYYAT", val: 200 },
    { key: "OZKGY", val: 200 },
    { key: "PAPIL", val: 200 },
    { key: "PARSN", val: 200 },
    { key: "PASEU", val: 200 },
    { key: "PATEK", val: 200 },
    { key: "PEHOL", val: 200 },
    { key: "PEKGY", val: 70 },
    { key: "PENTA", val: 200 },
    { key: "PETKM", val: 30 },
    { key: "PETUN", val: 200 },
    { key: "PGSUS", val: 30 },
    { key: "PNLSN", val: 200 },
    { key: "PNSUT", val: 200 },
    { key: "POLHO", val: 200 },
    { key: "POLTK", val: 200 },
    { key: "PSGYO", val: 200 },
    { key: "QUAGR", val: 70 },
    { key: "RALYH", val: 200 },
    { key: "RAYSG", val: 200 },
    { key: "REEDR", val: 70 },
    //{ key: "RGYAS", val: 200 },
    { key: "RYGYO", val: 200 },
    { key: "RYSAS", val: 200 },
    { key: "SAHOL", val: 30 },
    { key: "SARKY", val: 200 },
    { key: "SASA", val: 30 },
    { key: "SAYAS", val: 200 },
    { key: "SDTTR", val: 70 },
    { key: "SELEC", val: 200 },
    { key: "SISE", val: 30 },
    { key: "SKBNK", val: 70 },
    { key: "SMRTG", val: 70 },
    { key: "SNGYO", val: 200 },
    { key: "SNICA", val: 200 },
    { key: "SOKE", val: 200 },
    { key: "SOKM", val: 70 },
    { key: "SRVGY", val: 200 },
    { key: "SUNTK", val: 200 },
    { key: "SURGY", val: 200 },
    { key: "SUWEN", val: 200 },
    { key: "TABGD", val: 70 },
    { key: "TARKM", val: 200 },
    { key: "TATEN", val: 200 },
    { key: "TATGD", val: 200 },
    { key: "TAVHL", val: 70 },
    { key: "TCELL", val: 30 },
    { key: "TEZOL", val: 200 },
    { key: "THYAO", val: 30 },
    { key: "TKFEN", val: 70 },
    { key: "TKNSA", val: 70 },
    { key: "TMSN", val: 70 },
    { key: "TNZTP", val: 200 },
    { key: "TOASO", val: 30 },
    { key: "TRCAS", val: 200 },
    { key: "TRGYO", val: 200 },
    { key: "TSGYO", val: 200 },
    { key: "TSKB", val: 70 },
    { key: "TSPOR", val: 200 },
    { key: "TTKOM", val: 70 },
    { key: "TTRAK", val: 70 },
    { key: "TUKAS", val: 70 },
    { key: "TUPRS", val: 30 },
    { key: "TUREX", val: 200 },
    { key: "TURSG", val: 70 },
    { key: "ULKER", val: 70 },
    { key: "ULUUN", val: 200 },
    { key: "USAK", val: 200 },
    { key: "VAKBN", val: 70 },
    { key: "VAKFN", val: 200 },
    { key: "VAKKO", val: 200 },
    { key: "VBTYZ", val: 200 },
    { key: "VERUS", val: 200 },
    { key: "VESBE", val: 70 },
    { key: "VESTL", val: 70 },
    { key: "VKGYO", val: 200 },
    { key: "VRGYO", val: 200 },
    { key: "YAPRK", val: 200 },
    { key: "YATAS", val: 200 },
    { key: "YEOTK", val: 70 },
    { key: "YGGYO", val: 200 },
    //{ key: "YIGIT", val: 200 },
    { key: "YKBNK", val: 30 },
    { key: "YUNSA", val: 200 },
    { key: "YYLGD", val: 70 },
    { key: "ZOREN", val: 70 },
    { key: "ZRGYO", val: 200 },
    { key: "ACSEL", val: 400 },
    { key: "AGYO", val: 400 },
    { key: "AKENR", val: 400 },
    { key: "AKSUE", val: 400 },
    { key: "AKYHO", val: 400 },
    { key: "ALKA", val: 400 },
    //{ key: "ALKLC", val: 400 },
    { key: "ALMAD", val: 400 },
    { key: "ANELE", val: 400 },
    { key: "ANGEN", val: 400 },
    { key: "ARSAN", val: 400 },
    { key: "ARTMS", val: 400 },
    { key: "ARZUM", val: 400 },
    { key: "ATAGY", val: 400 },
    { key: "ATEKS", val: 400 },
    { key: "AVGYO", val: 400 },
    { key: "AVHOL", val: 400 },
    { key: "AVOD", val: 400 },
    { key: "AYCES", val: 400 },
    { key: "AYEN", val: 400 },
    { key: "BAKAB", val: 400 },
    { key: "BANVT", val: 400 },
    { key: "BARMA", val: 400 },
    { key: "BAYRK", val: 400 },
    { key: "BEGYO", val: 400 },
    { key: "BEYAZ", val: 400 },
    { key: "BJKAS", val: 400 },
    { key: "BLCYT", val: 400 },
    { key: "BMSCH", val: 400 },
    { key: "BMSTL", val: 400 },
    { key: "BNTAS", val: 400 },
    { key: "BOSSA", val: 400 },
    { key: "BRKSN", val: 400 },
    { key: "BURCE", val: 400 },
    { key: "BURVA", val: 400 },
    { key: "BVSAN", val: 400 },
    { key: "BYDNR", val: 400 },
    { key: "CELHA", val: 400 },
    { key: "CEOEM", val: 400 },
    { key: "CONSE", val: 400 },
    { key: "COSMO", val: 400 },
    { key: "CRDFA", val: 400 },
    { key: "CUSAN", val: 400 },
    { key: "DAGI", val: 400 },
    { key: "DARDL", val: 400 },
    { key: "DENGE", val: 400 },
    { key: "DERHL", val: 400 },
    { key: "DERIM", val: 400 },
    { key: "DESPC", val: 400 },
    { key: "DGATE", val: 400 },
    { key: "DITAS", val: 400 },
    { key: "DMRGD", val: 400 },
    { key: "DMSAS", val: 400 },
    { key: "DNISI", val: 400 },
    { key: "DOBUR", val: 400 },
    { key: "DOCO", val: 400 },
    { key: "DOFER", val: 400 },
    { key: "DOGUB", val: 400 },
    { key: "DOKTA", val: 400 },
    { key: "DURDO", val: 400 },
    { key: "DYOBY", val: 400 },
    { key: "DZGYO", val: 400 },
    { key: "EDATA", val: 400 },
    { key: "EDIP", val: 400 },
    { key: "EGEPO", val: 400 },
    { key: "EGSER", val: 400 },
    { key: "ENSRI", val: 400 },
    { key: "EPLAS", val: 400 },
    { key: "ERSU", val: 400 },
    { key: "ESEN", val: 400 },
    { key: "ETILR", val: 400 },
    { key: "EYGYO", val: 400 },
    { key: "FADE", val: 400 },
    { key: "FLAP", val: 400 },
    { key: "FMIZP", val: 400 },
    { key: "FONET", val: 400 },
    { key: "FORMT", val: 400 },
    { key: "FRIGO", val: 400 },
    { key: "GEDZA", val: 400 },
    { key: "GENTS", val: 400 },
    { key: "GLBMD", val: 400 },
    { key: "GLRYH", val: 400 },
    { key: "GMTAS", val: 400 },
    { key: "GSDDE", val: 400 },
    { key: "GZNMI", val: 400 },
    { key: "HATEK", val: 400 },
    { key: "HDFGS", val: 400 },
    { key: "HKTM", val: 400 },
    { key: "HUBVC", val: 400 },
    { key: "HURGZ", val: 400 },
    { key: "ICBCT", val: 400 },
    { key: "ICUGS", val: 400 },
    { key: "IHEVA", val: 400 },
    { key: "IHGZT", val: 400 },
    { key: "IHLAS", val: 400 },
    { key: "IHLGM", val: 400 },
    { key: "IHYAY", val: 400 },
    { key: "INGRM", val: 400 },
    { key: "INTEM", val: 400 },
    { key: "ISGSY", val: 400 },
    { key: "ISKPL", val: 400 },
    { key: "ISYAT", val: 400 },
    { key: "IZFAS", val: 400 },
    { key: "IZINV", val: 400 },
    { key: "KAPLM", val: 400 },
    { key: "KARYE", val: 400 },
    { key: "KFEIN", val: 400 },
    { key: "KGYO", val: 400 },
    { key: "KIMMR", val: 400 },
    { key: "KLMSN", val: 400 },
    { key: "KLSYN", val: 400 },
    { key: "KNFRT", val: 400 },
    { key: "KRGYO", val: 400 },
    { key: "KRONT", val: 400 },
    { key: "KRPLS", val: 400 },
    { key: "KRSTL", val: 400 },
    { key: "KRTEK", val: 400 },
    { key: "KUTPO", val: 400 },
    { key: "LIDFA", val: 400 },
    { key: "LINK", val: 400 },
    { key: "LKMNH", val: 400 },
    { key: "LUKSK", val: 400 },
    { key: "MACKO", val: 400 },
    { key: "MAKIM", val: 400 },
    { key: "MAKTK", val: 400 },
    { key: "MANAS", val: 400 },
    { key: "MARBL", val: 400 },
    { key: "MARTI", val: 400 },
    { key: "MEGAP", val: 400 },
    { key: "MEKAG", val: 400 },
    { key: "MEPET", val: 400 },
    { key: "MERCN", val: 400 },
    { key: "MERIT", val: 400 },
    { key: "MERKO", val: 400 },
    { key: "METRO", val: 400 },
    { key: "METUR", val: 400 },
    { key: "MHRGY", val: 400 },
    { key: "MNDRS", val: 400 },
    { key: "MNDTR", val: 400 },
    { key: "MRSHL", val: 400 },
    { key: "MSGYO", val: 400 },
    { key: "MTRKS", val: 400 },
    { key: "MZHLD", val: 400 },
    { key: "NIBAS", val: 400 },
    { key: "NUGYO", val: 400 },
    { key: "OBASE", val: 400 },
    { key: "OFSYM", val: 400 },
    { key: "ONCSM", val: 400 },
    //{ key: "ONRYT", val: 400 },
    { key: "ORCAY", val: 400 },
    { key: "OSMEN", val: 400 },
    { key: "OSTIM", val: 400 },
    { key: "OYAYO", val: 400 },
    { key: "OYLUM", val: 400 },
    { key: "OZGYO", val: 400 },
    { key: "OZRDN", val: 400 },
    { key: "OZSUB", val: 400 },
    //{ key: "OZYSR", val: 400 },
    { key: "PAGYO", val: 400 },
    { key: "PAMEL", val: 400 },
    { key: "PCILT", val: 400 },
    { key: "PENGD", val: 400 },
    { key: "PINSU", val: 400 },
    { key: "PKART", val: 400 },
    { key: "PKENT", val: 400 },
    { key: "PLTUR", val: 400 },
    { key: "PRDGS", val: 400 },
    { key: "PRKAB", val: 400 },
    { key: "PRKME", val: 400 },
    { key: "PRZMA", val: 400 },
    { key: "PSDTC", val: 400 },
    { key: "RNPOL", val: 400 },
    { key: "RODRG", val: 400 },
    { key: "RTALB", val: 400 },
    { key: "RUBNS", val: 400 },
    { key: "SAFKR", val: 400 },
    { key: "SAMAT", val: 400 },
    { key: "SANFM", val: 400 },
    { key: "SANKO", val: 400 },
    { key: "SEGYO", val: 400 },
    { key: "SEKFK", val: 400 },
    { key: "SEKUR", val: 400 },
    { key: "SELVA", val: 400 },
    { key: "SEYKM", val: 400 },
    { key: "SILVR", val: 400 },
    { key: "SKTAS", val: 400 },
    { key: "SKYLP", val: 400 },
    { key: "SKYMD", val: 400 },
    { key: "SMART", val: 400 },
    { key: "TDGYO", val: 400 },
    { key: "TEKTU", val: 400 },
    { key: "TERA", val: 400 },
    { key: "TGSAS", val: 400 },
    { key: "TLMAN", val: 400 },
    { key: "TMPOL", val: 400 },
    { key: "TRILC", val: 400 },
    { key: "TUCLK", val: 400 },
    { key: "TURGG", val: 400 },
    { key: "ULUFA", val: 400 },
    { key: "ULUSE", val: 400 },
    { key: "UNLU", val: 400 },
    { key: "VANGD", val: 400 },
    { key: "VERTU", val: 400 },
    { key: "VKFYO", val: 400 },
    { key: "VKING", val: 400 },
    { key: "YAYLA", val: 400 },
    { key: "YESIL", val: 400 },
    { key: "YGYO", val: 400 },
    { key: "YKSLN", val: 400 },
    { key: "YYAPI", val: 400 },
    { key: "ZEDUR", val: 400 },
    { key: "AKMGY", val: 400 },
    { key: "ATLAS", val: 400 },
    { key: "AVTUR", val: 400 },
    { key: "DAGHL", val: 400 },
    { key: "DGGYO", val: 400 },
    { key: "ETYAT", val: 400 },
    { key: "EUHOL", val: 400 },
    { key: "EUKYO", val: 400 },
    { key: "EUYO", val: 400 },
    { key: "GARFA", val: 400 },
    { key: "GRNYO", val: 400 },
    { key: "IDGYO", val: 400 },
    { key: "MARKA", val: 400 },
    { key: "MTRYO", val: 400 },
    { key: "SANEL", val: 400 },
    { key: "SELGD", val: 400 },
    { key: "SONME", val: 400 },
    { key: "TETMT", val: 400 },
    { key: "UFUK", val: 400 },
    { key: "ULAS", val: 400 },
];


const getSymbol = (ix) => {
    const symbols = JSON.parse(localStorage.getItem(key));

    if (symbols && symbols[ix]) {
        return symbols[ix].map((x) => { return x.Name });
    }

    switch (ix) {
        case "x030":
            return symbols030_statics();
        case "x070":
            return symbols070_statics();
        case "x200":
            return symbols200_statics();
        case "x400":
            return symbols400_statics();
        case "x030_weekly":
            return symbols030_weekly_statics();
        case "x070_weekly":
            return symbols070_weekly_statics();
        case "x200_weekly":
            return symbols200_weekly_statics();
        case "x400_weekly":
            return symbols400_weekly_statics();
    }

}



const symbols030_statics = () => {
    return symbols.filter(x => { return x.val === 30 }).map(x => { return x.key });
};

const symbols070_statics = () => {
    return symbols.filter(x => { return x.val === 70 }).map(x => { return x.key });
};

const symbols200_statics = () => {
    return symbols.filter(x => { return x.val === 200 }).map(x => { return x.key });
};

const symbols400_statics = () => {
    return symbols.filter(x => { return x.val === 400 }).map(x => { return x.key });
}

const symbols030_weekly_statics = () => {
    let outs = [];
    let weekly = symbols030().filter(x => { return !outs.includes(x); });
    return weekly;
}

const symbols070_weekly_statics = () => {
    let outs = ["AGROT", "AKFYE", "BINHO", "CWENE", "ENERY", "EUPWR", "IZENR", "KAYSE", "KLSER", "KTLEV", "LMKDC", "OBAMS", "REEDR", "TABGD"];
    let weekly = symbols070().filter(x => { return !outs.includes(x); });
    return weekly;
}

const symbols200_weekly_statics = () => {
    let outs = ["A1CAP", "ADGYO", "ALVES", "ASGYO", "ATAKP", "AVPGY", "BIENY", "BIGCH", "BORLS", "BORSK", "CATES", "CVKMD", "EBEBK", "EKOS", "EKSUN", "FORTE", "FZLGY", "GIPTA", "GOKNR", "GRTRK", "HATSN", "KBORU", "KOPOL", "KZGYO", "MEGMT", "MOGAN", "ODINE", "PASEU", "PATEK", "SURGY", "TARKM", "TATEN", "VRGYO"];
    let weekly = symbols200().filter(x => { return !outs.includes(x); });
    return weekly;
}

const symbols400_weekly_statics = () => {
    let outs = ["ARTMS", "BEGYO", "BYDNR", "DMRGD", "DOFER", "MARBL", "MEKAG", "MHRGY", "OFSYM", "SKYMD",];
    let weekly = symbols400().filter(x => { return !outs.includes(x); });
    return weekly;
}

export const symbolsxHarz = () => {
    return [
        "ADGYO",
        "DMRGD",
        "DOFER",
        "HATSN",
        "MEKAG",
        "REEDR",
        "TABGD",
        "VRGYO",
    ]
};



export const symbols030 = () => { return getSymbol("x030"); }
export const symbols070 = () => { return getSymbol("x070"); }
export const symbols200 = () => { return getSymbol("x200"); }
export const symbols400 = () => { return getSymbol("x400"); }

export const symbols030_weekly = () => { return getSymbol("x030_weekly"); }
export const symbols070_weekly = () => { return getSymbol("x070_weekly"); }
export const symbols200_weekly = () => { return getSymbol("x200_weekly"); }
export const symbols400_weekly = () => { return getSymbol("x400_weekly"); }

