import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 25;
const COLLECTION_NAME = "BIST_NEWS";

interface INewsData {
  news: {
    date: string;
    symbol: string;
    header: string;
    content: string;
    link: string;
  }[];
  requested: boolean;
  loaded: boolean;
}

const initialState: INewsData = {
  news: [],
  requested: false,
  loaded: false,
};

export function loadBistNews(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.BistNews.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.BISTNEWS,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("id", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {

          data.push({
            date: moment
            .unix(doc.data().date.seconds)
            .format("DD.MM.YY HH:mm"),
            symbol: doc.data().symbol,
            header: doc.data().header,
            content: doc.data().content,
            link: doc.data().link,
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.BISTNEWS,
          payload: {
            news: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function BistNews(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.BISTNEWS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const BistNewselector = (state: any) => state.BistNews;
