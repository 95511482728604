import moment from "moment";
import { fireStore } from "../../../firebase";
import { WEB_ACTION } from "./actions";
import { IData } from "./common";
const DATA_COUNT = 2;
const COLLECTION_NAME = "DS1";

interface IDailySentimentLastData {
  sentiment: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IDailySentimentLastData = {
  sentiment: [],
  requested: false,
  loaded: false,
};

export function loadDailySentimentLast(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.DailySentimentLast.requested) return;
    dispatch({
      type: WEB_ACTION.DAILY_SENTIMENT_LAST,
      payload: {
        requested: true,
      },
    });

    fireStore
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var sentimentData: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD");

          sentimentData.push({
            name: "sentiment",
            value: doc.data().sentiment,
            date: dateVal,
          });
        });

        dispatch({
          type: WEB_ACTION.DAILY_SENTIMENT_LAST,
          payload: {
            sentimentData:sentimentData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function DailySentimentLast(state = initialState, action: any) {
  switch (action.type) {
    case WEB_ACTION.DAILY_SENTIMENT_LAST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const dailySentimentLastSelector = (state: any) => state.DailySentimentLast;
