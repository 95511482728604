import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

let DATA_COUNT = 500;
const COLLECTION_NAME = "IND_BOTT_240";

interface IXUData {
  bott240: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  bott240: [],
  requested: false,
  loaded: false,
};

export function loadBOTT240(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.BOTT240.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.BOTT240,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("date", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {

          data.push({
            mov: doc.data().mov,
            value: doc.data().value?.toFixed(2),
            vip030: doc.data().vip030?.toFixed(2),
            xu030: doc.data().xu030,
            bollUp: doc.data().bollUp,
            bollMid: doc.data().bollMid,
            bollDown: doc.data().bollDown,
            mov2: doc.data().mov2?.toFixed(2),
            value2: doc.data().value2?.toFixed(2),
            date: moment(doc.data().date.toDate()).format("DD.MM.YY HH:mm")
          });
        });

        data.reverse();

        dispatch({
          type: SENTIMENT_ACTION.BOTT240,
          payload: {
            bott240: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function BOTT240(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.BOTT240:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const BOTT240selector = (state: any) => state.BOTT240;
