import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import {
  loadNotifications,
  notificationSelector,
} from "../../appRedux/reducers/sentiment/notifications";
import { useAuth } from "../../authentication";
import { firebaseApp } from "../../firebase";
import { useHistory } from "react-router-dom";
import { isProd, isStaging } from "../../util/Env";
import { isCooperateUser } from "../../appRedux/reducers/sentiment/permissions";
import { nsUserSelector } from "../../appRedux/reducers/sentiment/user";
import { isDayPeriod, isWeekPeriod } from "../../util/Period";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent4H = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const dispatch = useDispatch();
  const nsUser = useSelector(nsUserSelector);
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common?.pathname);
  const { authUser } = useAuth();

  useEffect(() => {
    dispatch(loadNotifications(firebaseApp, authUser?.uid));
  }, [authUser]);

  const notifications = useSelector(notificationSelector);
  const unreadNotifications =
    notifications && notifications?.data
      ? notifications?.data?.filter((n) => !n.read).length
      : 0;
  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const { userSignOut } = useAuth();
  const history = useHistory();
  const onLogoutClick = () => {
    userSignOut(() => {
      history.push("/");
    });
  };

  const selectedKeys = pathname?.substr(1);
  const defaultOpenKeys = selectedKeys?.split("/")[1];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="main">
              <Link to="/dashboard">
                <i className="icon icon-home" />
                <span className="gx-font-weight-bold">
                  <IntlMessages id="sidebar.home" />
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key="index">
              <Link to="/index/home">
                <span className="gx-font-weight-bold">
                  {" "}
                  <i className="icon icon-chart-line" />
                  <IntlMessages id="sidebar.index" />
                </span>
              </Link>
            </Menu.Item>

            <Menu.SubMenu
              key="bist"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <span className="gx-font-weight-bold">
                  {" "}
                  <i className="icon icon-chart-area-new" />
                  <IntlMessages id="sidebar.bist" />
                </span>
              }
            >

              <Menu.Item key="bist/lines">
                <Link to="/bist/lines">
                  <i className="icon icon-widgets" />
                  <span className="gx-font-weight-bold">
                    <IntlMessages id="sidebar.bist.symbols" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/explore">
                <Link to="/bist/explore">
                  <i className="icon icon-search-new" />
                  <span className="gx-font-weight-bold">
                    <IntlMessages id="sidebar.bist.explorer" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/follower">
                <Link to="/bist/follower">
                  <i className="icon icon-filter" />
                  <span className="gx-font-weight-bold">
                    <IntlMessages id="sidebar.bist.follower" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/mylines">
                <Link to="/bist/mylines">
                  <i className="icon icon-affix" />
                  <span className="gx-font-weight-bold">
                    <IntlMessages id="sidebar.follower_list" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/signals">
                <Link to="/bist/signals">
                  <i className="icon icon-growth" />
                  <span className="gx-font-weight-bold">
                    {!isWeekPeriod() ? <IntlMessages id="sidebar.bist.first_list" /> : <IntlMessages id="sidebar.bist.first7_list" />}
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/robot-halk">
                <Link to="/bist/robot-halk">
                  <i className="icon icon-growth" />
                  <span className="gx-font-weight-bold">
                    Robot-Halk
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/robot-sys">
                <Link to="/bist/robot-sys">
                  <i className="icon icon-growth" />
                  <span className="gx-font-weight-bold">
                    Robot-SYS
                  </span>
                </Link>
              </Menu.Item>

              {
                !isWeekPeriod() && <Menu.Item key="bist/trades">
                  <Link to="/bist/trades">
                    <i className="icon icon-pricing-table" />
                    <span className="gx-font-weight-bold">
                      <IntlMessages id="sidebar.bist.first_list_history" />
                    </span>
                  </Link>
                </Menu.Item>
              }


            </Menu.SubMenu>

            <Menu.Item key="account">
              <Link to="/account">
                <i className="icon icon-avatar" />
                <span className="gx-font-weight-bold">
                  <IntlMessages id="sidebar.account" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="bulletins">
              <Link to="/bulletins">
                <i className="icon icon-editor" />
                <span className="gx-font-weight-bold">
                  <IntlMessages id="sidebar.bulletins" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="notifications">
              <Link to="/notifications">
                <i className="icon icon-notification"></i>
                <span className="gx-font-weight-bold">
                  <IntlMessages id="sidebar.notifcations" />{" "}
                  {unreadNotifications > 0 && (
                    <span className="notification-count-badge">
                      {unreadNotifications}{" "}
                    </span>
                  )}
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="help">
              <Link to="/help">
                <i className="icon icon-question-circle" />
                <span className="gx-font-weight-bold">
                  <IntlMessages id="sidebar.help" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="exit">
              <Link to="/" onClick={onLogoutClick}>
                <i className="icon icon-close-circle" />
                <span className="gx-font-weight-bold">
                  <IntlMessages id="sidebar.logout" />
                </span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
        <UserProfile />
      </div>
    </>
  );
};

export default React.memo(SidebarContent4H);
