import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import { storage } from "../../../firebase";

const DATA_COUNT = 10;
const COLLECTION_NAME = "NS_USER_BULLETINS";

interface IBulletingData {
  bulletins: {
    name: string;
    url: string;
  }[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IBulletingData = {
  bulletins: [],
  requested: false,
  loaded: false,
};

export function loadBulletins(firebase: any) {

  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Bulletins.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.BULLETINS,
      payload: { requested: true, loaded: false, news: [] },
    });

    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}`)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var bulletinsData: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          bulletinsData.push({
            name: doc.data().name,
            url:doc.data().url,
            dateTime: doc.data().dateTime.toDate(),
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.BULLETINS,
          payload: {
            data: bulletinsData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Bulletins(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.BULLETINS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const bulletinsSelector = (state: any) => state.Bulletins;
