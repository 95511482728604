import axios from "axios";
import { useEffect, useState } from "react";
import queryString from "qs";
import { useLocation } from "react-router";
declare global {
  interface Window {
    iFrameResize?: (arg:any, id:string) => void;
  }
}
export const PaymentForm = (props: any) => {
  const { search } = useLocation();
  const [token, setToken] = useState<any>(null);

  useEffect(() => {
    const values = queryString.parse(search, { ignoreQueryPrefix: true });
    setToken(values?.token);
  }, [search]);
  return (
    <>
      <iframe
        src={`https://www.paytr.com/odeme/guvenli/${token}`}
        id="paytriframe"
        frameBorder="0"
        style={{
          width: "100%",
          height:"100%"
        }}
      ></iframe>

    </>
  );
};
