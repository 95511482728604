const SentimentEndeksler = [
    { value: "", label: `TUMU`},
    { value: "XAKUR", label: `XAKUR`},
    { value: "XBANK", label: `XBANK`},
    { value: "XBLSM", label: `XBLSM`},
    { value: "XELKT", label: `XELKT`},
    { value: "XFINK", label: `XFINK`},
    { value: "XGMYO", label: `XGMYO`},
    { value: "XGIDA", label: `XGIDA`},
    { value: "XUHIZ", label: `XUHIZ`},
    { value: "XHOLD", label: `XHOLD`},
    { value: "XILTM", label: `XILTM`},
    { value: "XINSA", label: `XINSA`},
    { value: "XK100", label: `XK100`},
    { value: "XKTUM", label: `XKTUM`},
    { value: "XKMYA", label: `XKMYA`},
    { value: "XMADN", label: `XMADN`},
    { value: "XMANA", label: `XMANA`},
    { value: "XMESY", label: `XMESY`},
    { value: "XKAGT", label: `XKAGT`},
    { value: "XSGRT", label: `XSGRT`},
    { value: "XUSIN", label: `XUSIN`},
    { value: "XSPOR", label: `XSPOR`},
    { value: "XTAST", label: `XTAST`},
    { value: "XUTEK", label: `XUTEK`},
    { value: "XTCRT", label: `XTCRT`},
    { value: "XTRZM", label: `XTRZM`},
    { value: "XULAS", label: `XULAS`},
    { value: "XYLDZ", label: `XYLDZ`},
];

export default SentimentEndeksler;