import moment from 'moment';
import 'moment-timezone';
import { SENTIMENT_ACTION } from "./actions";

const DATA_COUNT = 5000;
const COLLECTION_NAME = "TEST_INDEX";

interface IXUData {
  bist100: [];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  bist100: [],
  requested: false,
  loaded: false,
};

export function loadBist100(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Bist100.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.BIST100,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        let datas: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
            .unix(doc.data().dateTime.seconds);
          datas.push({
            value: doc.data().bist100 ?? 0,
            date: dateVal.toDate(),
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.BIST100,
          payload: {
            bist100: datas,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Bist100(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.BIST100:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const bist100Selector = (state: any) => state.Bist100;
