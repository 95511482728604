
import { Popover, Card, Button, Row, Col, Typography } from "antd";
import { PoweroffOutlined, UserOutlined } from '@ant-design/icons';
import { useAuth } from "../../authentication";
import { useHistory } from "react-router-dom";

const { Meta } = Card;
const { Text } = Typography

const UserProfile = () => {
  const { userSignOut, authUser } = useAuth();
  const history = useHistory();

  const onLogoutClick = () => {
    userSignOut(() => {
      history.push('/');
    });
  };

  const goToAccount = () => {
    history.push('/account');
  };

  const userMenuOptions = (
    <div style={{ minWidth: 200, marginBottom: -10 }}>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <Row justify="center">
            <Meta title={authUser?.email ?? 'User Email'} />
          </Row>
        </Col>
        <Col span={12}>
          <Button block onClick={goToAccount} icon={<UserOutlined />} />
        </Col>
        <Col span={12}>
          <Button block danger onClick={onLogoutClick} icon={<PoweroffOutlined />} />
        </Col>
      </Row>
    </div>
  );

  return (
    <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
      <div className="gx-align-items-center gx-avatar-row" style={{ height: "6vh" }}>
        <span className="gx-avatar-name" style={{ marginTop: -8, marginLeft: -8 }}>
          <Row justify="space-between" style={{ minWidth: "100%" }}>
            <Col xl={18} lg={19} xs={20}>
              <Text ellipsis style={{ color: "inherit" }}>
                {authUser?.displayName ?? authUser?.email}
              </Text>
            </Col>
            <Col xl={6} lg={5} xs={4}>
              <i className="icon icon-chevron-up gx-fs-xs gx-ml-2 gx-mt-1" />
            </Col>
          </Row>
        </span>
      </div>
    </Popover>
  );
};

export default UserProfile;