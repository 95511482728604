import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

const CustomScrollbars = (props) => {
    const thumbStyle = {
        backgroundColor: 'grey',
        borderRadius: '10px',
        border: '2px solid transparent',
        backgroundClip: 'content-box',
        width: '10px'
    };

    return (
        <Scrollbars
            {...props}
            autoHide
            renderTrackHorizontal={props => <div {...props} style={{ ...thumbStyle}} className="track-horizontal" />}
            renderThumbVertical={props => <div {...props} style={{ ...thumbStyle }} />}
        />
    );
};

export default CustomScrollbars;
