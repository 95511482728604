import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { auth } from "../../../firebase";
import { isProd } from "../../../util/Env";
import { PERMISSIONS } from "./permissions";
import { getWebTokenKey } from "../../../util/common";

const COLLECTION_NAME = "NS_USER_PERMISSION";
const UTM_COLLECTION = "NS_USER_UTM_CODES";
const PACKAGE_COLLECTION_NAME = "NS_PACKAGE";

interface IXUData {
  permissions: object;
  requested: boolean;
  loaded: boolean;
  needRefresh: boolean;
}

const initialState: IXUData = {
  permissions: {},
  requested: false,
  needRefresh: false,
  loaded: false,
};
export function refreshUser(firebase: any, userId: string) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    dispatch({
      type: SENTIMENT_ACTION.NS_USER,
      payload: {
        user: state.NsUser.user,
        agreement: state.NsUser.agreement,
        cooperate: state.NsUser.cooperate,
        loaded: false,
        requested: false,
        needRefresh: false,
      },
    });
  };
}
const createUtmCodesFromSearch = (search: string) => {
  try {
    const searchParams = new URLSearchParams(search);
    const utmParams: any = {};
    const keys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
    ];
    keys.forEach((key: string) => {
      if (searchParams.get(key)) {
        utmParams[key] = searchParams.get(key);
      }
    });

    return utmParams;
  } catch (e) {
    return null;
  }
};
const updateUtmCodes = async (firebase: any, userId: string) => {
  try {
    const utmUpdateTime = localStorage.getItem("utm-update-time");
    const now = new Date().getTime();

    if (utmUpdateTime !== null && utmUpdateTime !== "") {
      if (now - parseInt(utmUpdateTime) < 60 * 1000) {
        return;
      }
    } else {
      localStorage.setItem("utm-update-time", now.toString());
    }
    const code = await firebase
      .firestore()
      .collection(UTM_COLLECTION)
      .where("userId", "==", userId)
      .orderBy("createdAt", "desc")
      .limit(1)
      .get();

    const searchParam = createUtmCodesFromSearch(window.location.search);
    if (code.docs.length > 0) {
      const data = code.docs[0].data();

      if (searchParam) {
        const hasDiff = Object.keys(searchParam).some((key) => {
          if (!data[key] || searchParam[key] !== data[key]) return true;
        });
        if (hasDiff) {
          firebase
            .firestore()
            .collection(UTM_COLLECTION)
            .add({
              userId,
              ...searchParam,
              createdAt: new Date(),
            });
        }
      }
    } else if (searchParam && Object.keys(searchParam).length > 0) {
      firebase
        .firestore()
        .collection(UTM_COLLECTION)
        .add({
          userId,
          ...searchParam,
          createdAt: new Date(),
        });
    }
  } catch (err) {
    console.error(err);
  }
};
export function loadNsUser(firebase: any, userId: string) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (!userId) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
      return;
    }
    if (state.NsUser.requested && !state.NsUser.needRefresh) return;
    dispatch({
      type: SENTIMENT_ACTION.NS_USER,
      payload: {
        requested: true,
        loaded: false,
        sentiment: [],
        needRefresh: false,
      },
    });
    try {
      const userDoc = await firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc(userId)
        .onSnapshot(
          async (doc: any) => {
            if (doc.metadata.fromCache) return;
            updateUtmCodes(firebase, userId);
            const userData = doc?.data();
            if (userData && userData["meta.package-id"]) {
              await firebase
                .firestore()
                .collection(PACKAGE_COLLECTION_NAME)
                .doc(userData["meta.package-id"].toString())
                .onSnapshot((userPackage: any) => {
                  const packageData = userPackage?.data();
                  packageData[PERMISSIONS.EXCHANGER] =
                    userData["per.exchanger"] ?? false;

                  dispatch({
                    type: SENTIMENT_ACTION.NS_USER,
                    payload: {
                      user: packageData,
                      package: userData,
                      agreement: userData["meta.agreement"],
                      cooperate: userData["meta.cooperate"] ?? false,
                      loaded: true,
                      requested: true,
                      needRefresh: false,
                    },
                  });
                });
            } else {
              dispatch({
                type: SENTIMENT_ACTION.NS_USER,
                payload: {
                  user: null,
                  agreement: userData ? userData["meta.agreement"] : false,
                  cooperate: userData["meta.cooperate"] ?? false,
                  loaded: true,
                  requested: true,
                  needRefresh: false,
                },
              });
            }
            setTimeout(() => {
              const key=getWebTokenKey();
              if (userData && userData[key]) {
                const webToken = localStorage.getItem("web-token");
                if (webToken !== userData[key]) {
                  if (isProd()) {
                    auth.signOut();
                    return;
                  }
                }
              }
            }, 0);
          },
          (err: any) => {
            console.error("Unable to load package detail");
            console.error(err);

            dispatch({
              type: SENTIMENT_ACTION.NS_USER,
              payload: {
                user: null,
                agreement: false,
                cooperate: false,
                loaded: true,
                requested: true,
                needRefresh: true,
              },
            });
          }
        );
    } catch (err) {
      console.error("Unable to load user detail");
      console.error(err);
      dispatch({
        type: SENTIMENT_ACTION.NS_USER,
        payload: {
          user: null,
          agreement: false,
          cooperate: false,
          loaded: true,
          requested: true,
          needRefresh: true,
        },
      });
    }
  };
}

export default function NsUser(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.NS_USER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const nsUserSelector = (state: any) => state?.NsUser;
