import moment from "moment";
import { ImportImkbEndeksSenetlerMap } from "../../../util/ImkbEndeksSenetler";

export const linesMapper = (doc: any, name: string) => {
    var sentiPoints = doc.sentimentList;
    var SentiPer = 0
    if (sentiPoints?.length > 0)
        SentiPer = ((sentiPoints[sentiPoints.length - 1] - sentiPoints[sentiPoints.length - 2]) / sentiPoints[sentiPoints.length - 2] * 100);

    return {
        key: `${doc.time}_${doc.symbol}`,
        name: name,
        Index: doc.index,
        Symbol: doc.symbol,
        Sentiment: doc.sentiment,
        Order: doc.order,
        Group: doc.group,
        ODate: doc.openDate,
        OPrice: doc.openPrice,
        Price: doc.price,
        DailyChange: doc.dailyChange,
        Vbts: doc.vbts,
        First5to10: doc.first5to10,
        IsNewFirst5: doc.isNewFirst5,
        Viop: doc.viop,
        PerDay: doc.perDay,
        SentimentList: doc.sentimentList,
        PriceList: doc.priceList,
        ChangeList: doc.changeList,
        ReChangeList: doc.changeList?.slice().reverse(),
        dateTime: doc.dateTime?.toDate(),
        Time: doc.time ?? 0,
        UpdateTime: moment
            .unix(doc.dateTime?.seconds)
            .format("DD.MM.yy HH:mm"),
        LineDate: doc.dateTime?.seconds,
        Indexes: (ImportImkbEndeksSenetlerMap as any)[doc.symbol]?.val,
        VWMA: doc.vwma,
        SentiPer: Math.round(SentiPer * 100) / 100,
        STP: doc.stp,
        MTP: doc.mtp,

        CurrentSTP: doc.stp?.length > 0 ? doc.stp[doc.stp.length - 1] : 0,
        CurrentMTP: doc.stp?.length > 0 ? doc.stp[doc.stp.length - 1] : 0
    };

}

export const linesLiveMapper = (doc: any) => {
    return {
        date: moment
            .unix(doc.updateTime.seconds)
            .format("DD.MM.yy HH:mm"),
        dateList: doc.dateList,
        lines030: doc.lines030?.map((x: any) => linesMapper(x, "lines30")),
        lines070: doc.lines070?.map((x: any) => linesMapper(x, "lines070")),
        lines200: doc.lines200?.map((x: any) => linesMapper(x, "lines200")),
        lines400: doc.lines400?.map((x: any) => linesMapper(x, "lines400")),
    };
}