import moment from "moment";
import { WEB_ACTION } from "./actions";
import { IData } from "./common";

const DATA_COUNT = 100;
const COLLECTION_NAME = "OS1";

interface IOsiltorData {
  index: [];
  sentiment: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IOsiltorData = {
  sentiment: [],
  index: [],
  requested: false,
  loaded: false,
};

export function loadOsilator(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Osilator.requested) return;
    dispatch({
      type: WEB_ACTION.OSILATOR,
      payload: { requested: true, loaded: false, index: [], sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var sentimentData: Object[] = [];

        var indexData: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          const dateVal = moment
            .unix(doc.data().dateTime.seconds)
            .format("YYYY/MM/DD HH:mm");
          sentimentData.push({
            name: "osilator",
            value: [dateVal, doc.data().osilator],
            date: doc.data().dateTime.seconds,
          });

          indexData.push({
            name: "index",
            value: [dateVal, doc.data().index_value],
            date: doc.data().dateTime.seconds,

          });
        });
        indexData = indexData.reverse();
        sentimentData = sentimentData.reverse();
        dispatch({
          type: WEB_ACTION.OSILATOR,
          payload: {
            index: indexData,
            sentiment: sentimentData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Osilator(state = initialState, action: any) {
  switch (action.type) {
    case WEB_ACTION.OSILATOR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const osilatorSelector = (state: any) => state.Osilator;
