import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";

const DATA_COUNT = 2500;
const COLLECTION_NAME = "RS_ROBOT_BASKET_TRADES";

interface IXUData {
  data: any[];
  requested: boolean;
  loaded: boolean;
  lastUpdate: number;
}

const initialState: IXUData = {
  data: [],
  requested: false,
  loaded: false,
  lastUpdate: 0
};

const calcDuration = (open: any, close: any) => {
  var startDate = moment(open, "DD.MM.YYYY").toDate();
  var endDate = moment(close, "DD.MM.YYYY").toDate();
  var nextDay = new Date(startDate);
  var cnt = 0;
  do {
    cnt += (nextDay.getDay() >= 1 && nextDay.getDay() <= 5) ? 1 : 0;
    nextDay.setDate(nextDay.getDate() + 1);
  } while (nextDay <= endDate);

  return cnt;
}

export function loadRobotTrades(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.RobotTrades.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.ROBOT_BASKET_TRADES,
      payload: { requested: true, loaded: false, data: [] },
    });
    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var data: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          var docd = doc.data();

          var opendt = docd.openDate.split(".");
          data.push({
            Key: docd.key,
            Date: moment(docd.dateTime.toDate()).format("DD.MM.YY HH:mm"),
            Symbol: docd.symbol,
            ODate: docd.openDate,
            CDate: docd.closeDate,
            OPrice: docd.openPrice,
            CPrice: docd.closePrice,
            PerDay: docd.perDay,
            Percent: docd.percent,
            Duration: docd.closeDate != "" ? calcDuration(docd.openDate, docd.closeDate) : 0,
            dateTime: docd.dateTime.toDate(),
            dateOpen: new Date(opendt[2], opendt[1] - 1, opendt[0]),
            IncomingSymbol: docd.incomingSymbol
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.ROBOT_BASKET_TRADES,
          payload: {
            data: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function RobotTrades(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.ROBOT_BASKET_TRADES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const robotTradesSelector = (state: any) => state.RobotTrades;
