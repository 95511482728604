import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Input, Modal } from "antd";
import axios from "axios";
import { useState } from "react";
import { auth } from "../../firebase";

export const getCouponCodeData = async (code: string) => {
  const token = await auth.currentUser?.getIdToken();
  return axios
    .post(
      "https://europe-west1-algo-senti.cloudfunctions.net/payment-codes",
      {
        code,
      },
      {
        headers: {
          "x-app-uid": token,
        },
      }
    )
    .then((res) => {
      const data = res.data;

      if (data && data?.valid && data?.found) {
        return {
          valid: true,
          data: data.data
        }

      }
      if (data && !data?.found) {
        return {
          valid: false,
          error: "Kupon kodu bulunamadı"
        }

      }
      if (data && !data?.valid) {
        return {
          valid: false,
          error: "Kupon kodu artık geçersiz"
        }


      }
    })

}

export const CouponModal = ({ onClose, selectCode }: any) => {
  const [code, setCode] = useState("");
  const [validCode, setValidCode] = useState(false);
  const [couponError, setCouponError] = useState("");
  const [isChecking, setIsChecking] = useState(false);
  const [couponData, setCouponData] = useState(null);
  const checkCode = async () => {
    setValidCode(false);
    setCouponError("");
    setIsChecking(true);
    setCouponData(null);
    const token = await auth.currentUser?.getIdToken();
    setTimeout(async () => {
      const result = await getCouponCodeData(code).finally(() => {
        setIsChecking(false);
      })
      if (result?.valid) {
        setValidCode(true);
        setCouponData(result.data);
      }
      else {
        setCouponError(result?.error!);
      }
    }, 1000);
  };
  return (
    <Modal
      title="Sentiment Algo Ödeme Sistemi"
      open={true}
      onOk={() => {
        selectCode(couponData);
        onClose();
      }}
      okButtonProps={{ disabled: !validCode }}
      okText="Kodu Kullan"
      cancelText="İptal"
      onCancel={() => {
        onClose();
      }}
    >
      <div className="cupon-modal">
        <div className="cupon-modal-container">
          <Input
            placeholder="Kupon kodunuzu buraya girebilirsiniz"
            onChange={(e) => setCode(e.target.value)}
          />
          <Button disabled={isChecking || code === ""} onClick={checkCode}>
            Kontrol Et
          </Button>
        </div>
        {validCode && (
          <div className="coupon-valid">
            <CheckOutlined color="#0000ff" />
            Bu kodu kullanabilirsiniz
          </div>
        )}
        {couponError && couponError != "" && (
          <div className="coupon-valid">
            <CloseOutlined color="#0000ff" />
            {couponError}
          </div>
        )}
      </div>
    </Modal>
  );
};
