export const isStaging = () => {
  return process.env.REACT_APP_ENV === "staging";
};

export const isProd = () => {
  return process.env.REACT_APP_ENV === "prod";
};
export const isV2 = () => {
  const qs = window.location.pathname.split('/');
  return qs[qs.length - 1] === "v2" && !isProd();
};
