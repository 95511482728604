import { PAYMENT_ACTIONS } from "./actions";

const COLLECTION_NAME = "NS_PACKAGE_PRICES";
export interface IPackagePrice {
  name: string;
  prices: {
    monthly: number;
    three_monthly: number;
    yearly: number;
  }[];
  details: string[];
  mostPopular: boolean;
  id: string;
  paymentLevel: number;
  packageId: number;
  active: boolean;
}

interface IPriceData {
  prices: IPackagePrice[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IPriceData = {
  prices: [],
  requested: false,
  loaded: false,
};

export function loadPrices(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Prices.requested) return;
    dispatch({
      type: PAYMENT_ACTIONS.PAYMENT_PRICES,
      payload: { requested: true, loaded: false, news: [] },
    });
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}`)
      .orderBy("order", "asc")
      .onSnapshot((querySnapshot: any) => {
        var priceData: IPackagePrice[] = [];
        querySnapshot.forEach(function (doc: any) {
          priceData.push({
            name: doc.data().name,
            prices: doc.data().prices,
            details: doc.data().details,
            mostPopular: doc.data().mostPopular,
            id: doc.id,
            paymentLevel: doc.data().paymentLevel,
            packageId: doc.data().packageId,
            active: doc.data().active,
          });
        });

        dispatch({
          type: PAYMENT_ACTIONS.PAYMENT_PRICES,
          payload: {
            data: priceData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Prices(state = initialState, action: any) {
  switch (action.type) {
    case PAYMENT_ACTIONS.PAYMENT_PRICES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const priceSelector = (state: any) => state.Prices;
