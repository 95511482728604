import moment from "moment";
import "moment-timezone";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import klineInterval from "./params";

const DATA_COUNT = 250;
const COLLECTION_NAME = "NS_SIGNALS_70";

interface IXUData {
  worst70: { Basket: string[]; Outgoing: []; Incoming: []; Index: number };
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  worst70: {} as any,
  requested: false,
  loaded: false,
};

export function loadWorst70(firebase: any, period: number) {
  if (period < 0 || ![0, 10, 30, 99, 24].includes(period)) {
    console.log("Invalid period for signal 70. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }

  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Worst70.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.WORST_70,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    let ix = 0;
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}_P${period}`)
      .where("reverse", "==", 1)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        let data: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          data.push({
            key: ix++,
            name: "worst",
            Basket: doc.data().basket ?? [],
            Outgoing: doc.data().outgoing ?? [],
            Index: doc.data().index,
            Incoming: doc.data().incoming ?? [],
            Time: doc.data().time ?? 0,
            Date: moment(doc.data().dateTime.toDate())
              //.zone(0)
              .format("DD.MM.YY HH:mm"),
          });
        });

        dispatch({
          type: SENTIMENT_ACTION.WORST_70,
          payload: {
            worst70: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function Worst70(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.WORST_70:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const worst70Selector = (state: any) => state.Worst70;
