import React from "react";
import { Menu, Popconfirm } from "antd";
import { AppstoreOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";

import {
  loadNotifications,
  notificationSelector,
} from "../../appRedux/reducers/sentiment/notifications";
import { useAuth } from "../../authentication";
import { firebaseApp } from "../../firebase";
import { useHistory } from "react-router-dom";
import { isProd, isStaging } from "../../util/Env";
import { isCooperateUser } from "../../appRedux/reducers/sentiment/permissions";
import { nsUserSelector } from "../../appRedux/reducers/sentiment/user";
import { isWeekPeriod } from "../../util/Period";
import { Grid } from "antd"

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const { useBreakpoint } = Grid;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const dispatch = useDispatch();
  const nsUser = useSelector(nsUserSelector);
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common?.pathname);
  const { authUser } = useAuth();

  useEffect(() => {
    dispatch(loadNotifications(firebaseApp, authUser?.uid));
  }, [authUser]);

  const notifications = useSelector(notificationSelector);
  const unreadNotifications =
    notifications && notifications?.data
      ? notifications?.data?.filter((n) => !n.read).length
      : 0;
  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const { userSignOut } = useAuth();
  const history = useHistory();
  const onLogoutClick = () => {
    userSignOut(() => {
      history.push("/");
    });
  };

  const confirmLogout = () => {
    onLogoutClick();
  };

  const selectedKeys = pathname?.substr(1);
  const defaultOpenKeys = selectedKeys?.split("/")[1];

  const screens = useBreakpoint();

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <CustomScrollbars
          // className="gx-layout-sider-scrollbar"
          style={{
            height: screens.lg ? "calc(94vh - 78px)" : screens.xs ? "calc(94vh - 65px)" : "calc(94vh - 91px)",
            position: "relative",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
            style={{ overflowX: "hidden", overflowY: "auto" }}
          >
            <Menu.Item key="main">
              <Link to="/dashboard">
                <i className="icon icon-home" />
                <span className="gx-font-weight-bold">
                  <IntlMessages id="sidebar.home" />
                </span>
              </Link>
            </Menu.Item>

            <Menu.Item key="index">
              <Link to="/index/home"><i className="icon icon-chart-line" />
                <span className="gx-font-weight-bold">
                  {" "}

                  <IntlMessages id="sidebar.index" />
                </span>
              </Link>
            </Menu.Item>

            <Menu.SubMenu
              key="bist"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <>
                  <i className="icon icon-chart-area-new" />
                  <span className="gx-font-weight-bold">
                    {" "}

                    <IntlMessages id="sidebar.bist" />
                  </span> </>
              }
            >
              <Menu.Item key="bist/home">
                <Link to="/bist/home">
                  <i className="icon icon-menu" />
                  <span className="gx-font-weight-bold">
                    <IntlMessages id="sidebar.bist.home" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/lines">
                <Link to="/bist/lines">
                  <AppstoreOutlined style={{ fontSize: "16px" }} className="gx-mr-2" />
                  <span className="gx-font-weight-bold gx-ml-0">
                    <IntlMessages id="sidebar.bist.symbols" />
                  </span>
                </Link>
              </Menu.Item>

              {
                <Menu.Item key="bist/follower">
                  <Link to="/bist/follower">
                    <i className="icon icon-filter" />
                    <span className="gx-font-weight-bold">
                      <IntlMessages id="sidebar.follower" />
                    </span>
                  </Link>
                </Menu.Item>
              }

              <Menu.Item key="bist/explore">
                <Link to="/bist/explore">
                  <i className="icon icon-search-new" />
                  <span className="gx-font-weight-bold">
                    <IntlMessages id="sidebar.bist.explorer" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/signals">
                <Link to="/bist/signals">
                  <i className="icon icon-growth" />
                  <span className="gx-font-weight-bold">
                    {!isWeekPeriod() ? (
                      <IntlMessages id="sidebar.bist.first_list" />
                    ) : (
                      <IntlMessages id="sidebar.bist.first7_list" />
                    )}
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/worst">
                <Link to="/bist/worst">
                  <i className="icon icon-down" />
                  <span className="gx-font-weight-bold">
                    <IntlMessages id="sidebar.bist.last_list" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/timeline">
                <Link to="/bist/timeline">
                  <i className="icon icon-map-clustering" />
                  <span className="gx-font-weight-bold">
                    <IntlMessages id="sidebar.symbol_signal" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/mylines">
                <Link to="/bist/mylines">
                  <i className="icon icon-affix" />
                  <span className="gx-font-weight-bold">
                    <IntlMessages id="sidebar.follower_list" />
                  </span>
                </Link>
              </Menu.Item>

              {/* {!isProd() && nsUser.cooperate && (
                <Menu.Item key="bist/monitor">
                  <Link to="/bist/monitor">
                    <i className="icon icon-view-o" />
                    <span>
                      <IntlMessages id="sidebar.bist.monitor" />
                    </span>
                  </Link>
                </Menu.Item>
              )} */}
            </Menu.SubMenu>

            <Menu.SubMenu
              key="perform"
              popupClassName={getNavStyleSubMenuClass(navStyle)}
              title={
                <>
                  <i className="icon icon-icon" />
                  <span className="gx-font-weight-bold">
                    {" "}

                    <IntlMessages id="page.bist.explorer.performance" />
                  </span>
                </>
              }
            >
              <Menu.Item key="bist/trades">
                <Link to="/bist/trades">
                  <i className="icon icon-pricing-table" />
                  <span className="gx-font-weight-bold">
                    <IntlMessages id="sidebar.bist.first_list_history" />
                  </span>
                </Link>
              </Menu.Item>

              <Menu.Item key="bist/shortTrades">
                <Link to="/bist/shortTrades">
                  <i className="icon icon-pricing-table" />
                  <span className="gx-font-weight-bold">
                    <IntlMessages id="sidebar.bist.last_list_history" />
                  </span>
                </Link>
              </Menu.Item>

              {nsUser.cooperate && (
                <Menu.Item key="bist/balances">
                  <Link to="/bist/balances">
                    <i className="icon icon-dasbhoard" />
                    <span className="gx-font-weight-bold">
                      <IntlMessages id="sidebar.bist.profit_charts" />
                    </span>
                  </Link>
                </Menu.Item>
              )}
            </Menu.SubMenu>

            {(nsUser.cooperate || nsUser?.package?.["per.exchanger"]) && (
              <Menu.SubMenu
                key="exchanger"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <>  <i className="icon icon-chart-pie" />
                    <span className="gx-font-weight-bold">
                      {" "}

                      <IntlMessages id="sidebar.barter" />
                    </span>
                  </>
                }
              >
                <Menu.Item key="exchanger/symbol">
                  <Link to="/exchanger/symbol">
                    <i className="icon icon-extensions" />
                    <span className="gx-font-weight-bold">
                      <IntlMessages id="sidebar.symbol" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="exchanger/corporate">
                  <Link to="/exchanger/corporate">
                    <i className="icon icon-crm" />
                    <span className="gx-font-weight-bold">
                      <IntlMessages id="sidebar.organisation" />
                    </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="exchanger/signal">
                  <Link to="/exchanger/signal">
                    <i className="icon icon-megaphone" />
                    <span className="gx-font-weight-bold">
                      <IntlMessages id="sidebar.signal" />
                    </span>
                  </Link>
                </Menu.Item>
              </Menu.SubMenu>
            )}

            {nsUser.cooperate && !isProd() && (
              <Menu.SubMenu
                key="zephlex"
                popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={
                  <><i className="icon icon-apps" />
                    <span className="gx-font-weight-bold">
                      {" "}

                      <IntlMessages id="sidebar.zephlex" />
                    </span>
                  </>
                }
              >
                {nsUser.cooperate && !isProd() && (
                  <Menu.Item key="tbot/1">
                    <Link to="/tbot/1">
                      <i className="icon icon-map-clustering" />
                      <span className="gx-font-weight-bold">
                        <IntlMessages id="sidebar.tbot" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}
                {nsUser.cooperate && !isProd() && (
                  <Menu.Item key="tbot/240">
                    <Link to="/tbot/240">
                      <i className="icon icon-map-clustering" />
                      <span className="gx-font-weight-bold" >
                        <IntlMessages id="sidebar.tbot_240" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}
                {nsUser.cooperate && !isProd() && (
                  <Menu.Item key="tbot/xu100">
                    <Link to="/tbot/xu100">
                      <i className="icon icon-map-clustering" />
                      <span className="gx-font-weight-bold" >
                        <IntlMessages id="sidebar.xu100stp" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}
                {nsUser.cooperate && !isProd() && (
                  <Menu.Item key="bist/history">
                    <Link to="/bist/history">
                      <i className="icon icon-map-clustering" />
                      <span className="gx-font-weight-bold">
                        <IntlMessages id="sidebar.historicaldata" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}
                {nsUser.cooperate && !isProd() && (
                  <Menu.Item key="bist/pairLines">
                    <Link to="/bist/pairLines">
                      <i className="icon icon-map-clustering" />
                      <span className="gx-font-weight-bold">
                        <IntlMessages id="sidebar.binarysymboldata" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}

                {nsUser.cooperate && !isProd() && (
                  <Menu.Item key="bist/metrics">
                    <Link to="/bist/metrics">
                      <i className="icon icon-map-clustering" />
                      <span className="gx-font-weight-bold">
                        <IntlMessages id="sidebar.pnl" />
                      </span>
                    </Link>
                  </Menu.Item>
                )}

                {nsUser.cooperate && !isProd() && (
                  <Menu.Item key="bist/robotBaskets">
                    <Link to="/bist/robotBaskets">
                      <i className="icon icon-map-clustering" />
                      <span className="gx-font-weight-bold">
                        Robot Baskets
                      </span>
                    </Link>
                  </Menu.Item>
                )}
                {nsUser.cooperate && !isProd() && (
                  <Menu.Item key="bist/robotTrades">
                    <Link to="/bist/robotTrades">
                      <i className="icon icon-map-clustering" />
                      <span className="gx-font-weight-bold">
                        Robot Trades
                      </span>
                    </Link>
                  </Menu.Item>
                )}
                {nsUser.cooperate && !isProd() && (
                  <Menu.Item key="bist/robot-kat030">
                    <Link to="/bist/robot-kat030">
                      <i className="icon icon-map-clustering" />
                      <span className="gx-font-weight-bold">
                        Robot Katılım030
                      </span>
                    </Link>
                  </Menu.Item>
                )}
                {nsUser.cooperate && !isProd() && (
                  <Menu.Item key="bist/robot-kat100">
                    <Link to="/bist/robot-kat100">
                      <i className="icon icon-map-clustering" />
                      <span className="gx-font-weight-bold">
                        Robot Katılım100
                      </span>
                    </Link>
                  </Menu.Item>
                )}
              </Menu.SubMenu>
            )}

            {!isProd() && (
              <Menu.Item key="messages">
                <Link to="/messages">
                  <i className="icon icon-email" />
                  <span className="gx-font-weight-bold">
                    <IntlMessages id="sidebar.messages" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="account">
              <Link to="/account">
                <i className="icon icon-avatar" />
                <span className="gx-font-weight-bold">
                  <IntlMessages id="sidebar.account" />
                </span>
              </Link>
            </Menu.Item>
            {!isProd() && (
              <Menu.Item key="hub">
                <Link to="/hub">
                  <i className="icon icon-chat" />
                  <span className="gx-font-weight-bold">
                    <IntlMessages id="sidebar.chat" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="bulletins">
              <Link to="/bulletins">
                <i className="icon icon-editor" />
                <span className="gx-font-weight-bold">
                  <IntlMessages id="sidebar.bulletins" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="notifications">
              <Link to="/notifications">
                <i className="icon icon-notification"></i>
                <span className="gx-font-weight-bold">
                  <IntlMessages id="sidebar.notifcations" />{" "}
                  {unreadNotifications > 0 && (
                    <span className="notification-count-badge">
                      {unreadNotifications}{" "}
                    </span>
                  )}
                </span>
              </Link>
            </Menu.Item>
            {!isProd() && (
              <Menu.Item key="alarms">
                <Link to="/alarms">
                  <i className="icon icon-alert"></i>
                  <span className="gx-font-weight-bold">
                    <IntlMessages id="sidebar.notification" />
                  </span>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item key="help">
              <Link to="/help">
                <i className="icon icon-question-circle" />
                <span className="gx-font-weight-bold">
                  <IntlMessages id="sidebar.help" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="whatsapp">
              <a href="https://api.whatsapp.com/send/?phone=905468317669&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
                <i className="icon icon-phone" />

                <span className="gx-font-weight-bold">


                  <IntlMessages id="sidebar.whatsapp" />
                </span>
              </a>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
        <UserProfile />
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
