import antdDE from "antd/lib/locale-provider/de_DE";
import deMessages from "../locales/de_DE.json";

const deLang = {
  messages: {
    ...deMessages
  },
  antd: antdDE,
  locale: 'de',
};
export default deLang;
