import axios from "axios";
import { useEffect, useState } from "react";
import queryString from "qs";
import { useLocation } from "react-router";
import { Card } from "antd";
import { Link } from "react-router-dom";

export const PaymentSuccess = (props: any) => {
  return (
    <Card>
      <div
        style={{
          padding: 30,
          display: "flex",
          flexDirection: "column",
          gap: 30,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img width={64} height={64} src="/assets/images/confetti.png" />
        <div style={{ fontWeight: "bold", fontSize: 28, paddingTop: 20 }}>
          Tebrikler!
        </div>
        <div
          style={{
            paddingLeft: 30,
            paddingRight: 30,
            display: "flex",
            flexDirection: "column",
            gap: 10,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>Ödemeniz başarıyla alınmıştır.</div>
          <div>
            Hesap detaylarına <Link to="/account">buradan</Link>{" "}
            ulaşabilirsiniz.
          </div>
        </div>
      </div>
    </Card>
  );
};
