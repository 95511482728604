import moment from "moment";
import { EMA } from "technicalindicators";
import { IData } from "../web/common";
import { SENTIMENT_ACTION } from "./actions";

const DATA_COUNT = 10;
const COLLECTION_NAME = "NS_STOCK_ALARM_STATE";
interface CRP_ALARM {
  symbols: string;
  incoming: string;
  outgoing: string;
  name: string;
  id: string;
}

interface ICRPAlarmState {
  alarms: CRP_ALARM[];

  requested: boolean;
  loaded: boolean;
}

const initialState: ICRPAlarmState = {
  alarms: [],
  requested: false,
  loaded: false,
};

export function loadStockAlarmState(firebase: any, ownerId: string) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.StockAlarmState.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.STOCK_ALARM_STATE,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .doc(ownerId)
      .collection("alarms")
      .orderBy("dateTime", "desc")
      .limit(5)
      .onSnapshot((querySnapshot: any) => {
        let alarms: any = [];
        querySnapshot.forEach(function (doc: any) {
          alarms.push({...doc.data(),id:doc.id});
        });

        dispatch({
          type: SENTIMENT_ACTION.STOCK_ALARM_STATE,
          payload: {
            alarms,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function StockAlarmState(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.STOCK_ALARM_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const stockAlarmStateSelector = (state: any) => state.StockAlarmState;
