export const getWebTokenKey = () => {
  try {
    if (!window?.location?.href) return "meta.web-token";
    if (window.location.href.includes("web.sentimentalgo.net"))
      return "meta.web-token-net";

    return "meta.web-token";
  } catch (e) {
    return "meta.web-token";
  }
};
