import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  nsUserSelector,
  refreshUser,
  loadNsUser,
} from "../appRedux/reducers/sentiment/user";

import asyncComponent from "util/asyncComponent";
import { Modal } from "antd";
import { Checkbox } from "antd";
import { userApi } from "../api/user/user-api";
import { LoadingWidget } from "../components/LoadingWidget/LoadingWidget";
import { firebaseApp } from "../firebase";
import { useAuth } from "../authentication";
import { useAppDispatch } from "../NextApp";
import { ToastContainer } from "react-toastify";
import { Alarm } from "./Bist/Explore/Alarm";
import "react-toastify/dist/ReactToastify.css";
import { isProd } from "../util/Env";
import Payment from "./Payment";
import axios from "axios";
import { setSymbols } from "../util/ImkbSemboller";

const App = ({ match }) => {
  const [agreed, setAgreed] = useState(false);
  const [waitingAgreement, setWaitingAgreement] = useState(false);
  const { authUser } = useAuth();

  const dispatch = useAppDispatch();
  const nsUser = useSelector(nsUserSelector);

  useEffect(() => {
    setSymbols();
  }, []);

  useEffect(() => {
    try {
      if (nsUser?.loaded && authUser) {
        if (process.env.REACT_APP_ZEPHLEX_TRACKER_URL) {
          const time = localStorage.getItem("zephlex_utl");
          let update = true;
          if (time && !isNaN(time)) {
            const diff = new Date().getTime() - parseInt(time);
            if (diff < 1000 * 60 * 5) {
              update = false;
            }
          }

          if (update) {
            localStorage.setItem("zephlex_utl", new Date().getTime());
            axios.get(process.env.REACT_APP_ZEPHLEX_TRACKER_URL, {
              headers: {
                "x-user-id": authUser?.uid,
              },
            });
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  if (nsUser?.loaded && !nsUser?.agreement)
    return (
      <Modal
        title="Üyelik Sözleşmesi Onayı"
        maskClosable={false}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
        okText="Devam et"
        okButtonProps={{
          style: {
            cursor: !agreed ? "not-allowed" : "pointer",
          },
        }}
        onOk={() => {
          if (!agreed) return false;
          setWaitingAgreement(true);
          dispatch(userApi.endpoints.agreement.initiate())
            .unwrap()
            .finally(() => {
              dispatch(refreshUser()).then(() => {
                dispatch(loadNsUser(firebaseApp, authUser.uid));
              });
              setWaitingAgreement(false);
            });
        }}
        open={true}
      >
        {waitingAgreement ? (
          <LoadingWidget />
        ) : (
          <div className="gx-modal-box-row">
            <div className="agreement-content">
              <a
                target="_blank"
                href="https://firebasestorage.googleapis.com/v0/b/algo-senti.appspot.com/o/ns%2Flegal%2FSentiment%20Algo%20Uyelik%20Sozlesmesi.pdf?alt=media&token=73e3b103-6fd3-417c-9e6a-0f91dc6e6516"
              >
                Üyelik Sözleşmesini Görüntüle
              </a>
            </div>
            <div>
              <Checkbox onChange={(e) => setAgreed(e.target.checked)}>
                {" "}
                Üyelik sözleşmesini okudum, kabul ediyorum.
              </Checkbox>
            </div>
          </div>
        )}
      </Modal>
    );
  const noPadding = window.location.pathname.includes("/hub");
  return (
    <div
      className={`gx-main-content-wrapper ${noPadding ? "main-content-no-padding" : ""
        }`}
    >
      <Switch>
        <Route
          path={`${match.url}dashboard`}
          component={asyncComponent(() => import("./Dashboard2"))}
        />
        <Route
          path={`${match.url}index`}
          component={asyncComponent(() => import("./Web"))}
        />
        <Route
          path={`${match.url}bist`}
          component={asyncComponent(() => import("./Bist"))}
        />
        <Route
          path={`${match.url}messages`}
          component={asyncComponent(() => import("./Messages"))}
        />
        <Route
          path={`${match.url}help`}
          component={asyncComponent(() => import("./Help"))}
        />
        <Route
          path={`${match.url}account`}
          component={asyncComponent(() => import("./Account"))}
        />
        <Route
          path={`${match.url}bulletins`}
          component={asyncComponent(() => import("./Bulletins"))}
        />
        <Route
          path={`${match.url}dashboard2`}
          component={asyncComponent(() => import("./Dashboard2"))}
        />
        <Route
          path={`${match.url}notifications`}
          component={asyncComponent(() => import("./Notifications"))}
        />
        <Route
          path={`${match.url}alarms`}
          component={asyncComponent(() => import("./Alarms"))}
        />
        <Route
          path={`${match.url}changelog`}
          component={asyncComponent(() => import("./ChangeLog"))}
        />
        <Route
          path={`${match.url}tbot`}
          component={asyncComponent(() => import("./Bott"))}
        />
        <Route
          path={`${match.url}exchanger`}
          component={asyncComponent(() => import("./Exchanger"))}
        />
        <Route path={`${match.url}payment`} component={Payment} />
        <Route
          path={`${match.url}hub`}
          component={asyncComponent(() => import("./Bist/Hub"))}
        />
        <Route
          path={`${match.url}hub-profile`}
          component={asyncComponent(() => import("./Bist/Hub/UserDetailsForm"))}
        />
        <Route
          path={`${match.url}daily-bulletin`}
          component={asyncComponent(() => import("./Bulletins/Daily"))}
        />
      </Switch>
      <ToastContainer></ToastContainer>
      {!isProd() && <Alarm />}
    </div>
  );
};

export default App;
