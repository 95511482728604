import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 1000;
const COLLECTION_NAME = "IND_XUSTPMTP";

interface IXUData {
  data: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  data: [],
  requested: false,
  loaded: false,
};

export function loadXuStpMtp(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.XuStpMtp.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.XUSTPMTP,
      payload: { requested: true, loaded: false, data: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("date", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var data: Object[] = [];
        querySnapshot.forEach(function (doc: any) {

          data.push({
            xu100: doc.data().xu100,
            xu030: doc.data().xu030,
            xu030MTP: doc.data().xu030MTP,
            xu100MTP: doc.data().xu100MTP,
            xu030STP: doc.data().xu030STP,
            xu100STP: doc.data().xu100STP,
            date: moment(doc.data().date.toDate()).format("DD.MM.YY")
          });
        });

        data.reverse();

        dispatch({
          type: SENTIMENT_ACTION.XUSTPMTP,
          payload: {
            data: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function XuStpMtp(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.XUSTPMTP:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const xuStpMtpSelector = (state: any) => state.XuStpMtp;
