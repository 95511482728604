const steps = [
    {
        selector: '.first-step',
        content: 'Bu alan önemli bir kısmı gösteriyor!',
        position: 'bottom', // Tooltip'in nerede görüneceği
    },
    {
        selector: '.second-step',
        content: 'Bu da ikinci önemli alan.',
        position: 'right',
    },
    // Daha fazla adım ekleyebilirsiniz
];

export default steps;
