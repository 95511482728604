import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

let DATA_COUNT = 1000;
const COLLECTION_NAME = "IND_BOTT_15";

interface IXUData {
  bott015: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  bott015: [],
  requested: false,
  loaded: false,
};

function calculateCross(prev: number, current: number): { cross: boolean, point: number | null } {
  let cross = false;
  let point: number | null = null;



  if (prev < -80 && current >= -80) {
    point = -1;
    cross = true;
  } else if (prev < 0 && current >= 0) {
    point = 1;
    cross = true;
  } else if (prev < 80 && current >= 80) {
    point = 2;
    cross = true;
  } else if (prev >= -80 && current < -80) {
    point = -2;
    cross = true;
  } else if (prev >= 0 && current < 0) {
    point = -1;
    cross = true;
  } else if (prev >= 80 && current < 80) {
    point = 1;
    cross = true;
  }

  return { cross, point };
}

export function loadBOTT015(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.BOTT015.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.BOTT015,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("date", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var data: any[] = [];

        querySnapshot.forEach(function (doc: any) {
          const docData = doc.data();
          data.push({
            period: 15,
            mov: docData.mov,
            value: docData.value,
            value2: docData.value2,
            vip030: docData.vip030,
            xu030: docData.xu030,
            bollUp: docData.bollUp,
            bollMid: docData.bollMid,
            bollDown: docData.bollDown,
            date: moment(docData.date.toDate()).format("DD.MM.YY HH:mm"),
          });
        });

        data.reverse();

        for (let i = 1; i < data.length; i++) {
          const prev = data[i - 1].mov;
          const current = data[i].mov;

          const { cross, point } = calculateCross(prev, current);

          data[i] = {
            ...data[i],
            cross: cross,
            point: cross ? point : null
          };
        }

        dispatch({
          type: SENTIMENT_ACTION.BOTT015,
          payload: {
            bott015: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function BOTT015(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.BOTT015:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const BOTT015selector = (state: any) => state.BOTT015;
