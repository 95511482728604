import React, { createContext, useContext } from "react";

import { useState } from "react";

export const usePaymentProvider = () => {
  const [details, setDetails] = useState<any[]>([]);

  return {
    details,
    setDetails,
  };
};

interface PaymentContextContext {
  details: any;
  setDetails: any;
}
const paymentContext = createContext<PaymentContextContext>({
  details: null,
  setDetails: null,
});

export function PaymentProvider({ children }: any) {
  const paymentDetails = usePaymentProvider();

  return (
    <paymentContext.Provider value={paymentDetails}>
      {children}
    </paymentContext.Provider>
  );
}

export const usePayment = () => {
  return useContext(paymentContext);
};
