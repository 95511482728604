import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 100;
const COLLECTION_NAME = "NS_EXCHANGE_CORPORATESIGNAL";

interface IExchangeCorporateSignalData {
  data: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IExchangeCorporateSignalData = {
  data: [],
  requested: false,
  loaded: false,
};

export function loadExchangeCorporateSignal(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {

    const state = getState();
    if (state.ExchangeCorporateSignal.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.EXCHANGE_CORPORATE_SIGNAL,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("date", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var data: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
          data.push({
            id: doc.data().id,
            code : doc.data().code,
            corporate : doc.data().corporate,
            date: moment(doc.data().date.toDate()).format("DD.MM.YY HH:mm"),
            signal: doc.data().signal,
            text: doc.data().text,
            direction: doc.data().direction,
            money: doc.data().money,
            money5 : doc.data().money5,
            money30 : doc.data().money30,
            netper30 : doc.data().netper30,
          });

        });

        dispatch({
          type: SENTIMENT_ACTION.EXCHANGE_CORPORATE_SIGNAL,
          payload: {
            data: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function ExchangeCorporateSignal(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.EXCHANGE_CORPORATE_SIGNAL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const exchangeCorporateSignalSelector = (state: any) => state.ExchangeCorporateSignal;
