import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import klineInterval from "./params";
import ImkbEndeksSenetler from "../../../util/ImkbEndeksSenetler";

export const DATA_COUNT = 1;
const COLLECTION_NAME = "NS_LINES";

interface IXUData {
  data: any;
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  data: {
    updateTime: "",
    date: "",
    lines: [],
  },
  requested: false,
  loaded: false,
};

const lineMapper = (index: number, lines: any, dates: any) => {
  return lines?.map(function (e: any) {
    var sentiPoints = e.sentimentList
    return {
      Key: e.symbol,
      Index: index,
      OPrice: e.openPrice != "0" ? e.openPrice : "",
      ReChangeList: e.changeList.slice().reverse(),
      STP: e.stp?.reverse(),
      DateList: dates,
      SentiPer: ((sentiPoints[sentiPoints.length - 1] - sentiPoints[sentiPoints.length - 2]) / sentiPoints[sentiPoints.length - 2] * 100).toFixed(2),
      ChangeList: e.changeList,
      DailyChange: e.dailyChange,
      OpenDate: e.openDate,
      OpenPrice: e.openPrice,
      Order: e.order,
      PerDay: e.perDay,
      Price: e.price,
      PriceList: e.priceList,
      Sentiment: e.sentiment,
      SentimentList: e.sentimentList,
      Symbol: e.symbol,
      Vol: e.Vol,
      VolPerDay: e.volPerDay,

      Indexes: ImkbEndeksSenetler.filter(x => x.name == e.symbol)[0]?.val,
      Vbts: e.vbts,
      First5to10: e.first5to10,
      IsNewFirst5: e.isNewFirst5,
      Viop: e.viop,
      Group: e.group,
      Time: e.time ?? 0,
    };
  });
}

export function loadLines(firebase: any, period: number) {
  if (period < 0 || ![0, 10, 30, 99].includes(period)) {
    console.log("Invalid period for line 30. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.Lines.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.LINES,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}_P${period}`)
      .orderBy("date", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((res: any) => {
        var lines: any = {
          updateTime: "",
          date: "",
          lines: [],
        };

        res.forEach(function (doc: any) {
          let last = doc.data();
          let mapped = [
            ...lineMapper(30, last.lines030, last.dateList),
            ...lineMapper(70, last.lines070, last.dateList),
            ...lineMapper(200, last.lines200, last.dateList),
            ...lineMapper(400, last.lines400, last.dateList)
          ];
          mapped.sort((a: any, b: any) => { return a.Order - b.Order });
          lines = {
            lines: mapped,
            updateTime: moment.unix(last.updateTime.seconds).format("DD.MM.yy HH:mm"),
            date: moment.unix(last.date.seconds).format("DD.MM.yy HH:mm"),

          }
        });

        dispatch({
          type: SENTIMENT_ACTION.LINES,
          payload: {
            data: lines,
            loaded: true,
            requested: true
          },
        });
      });
  };
}

export default function Lines(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.LINES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const linesSelector = (state: any) => state.Lines;
