import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router";
import { CustomerForm } from "./CustomerForm";
import { InitialScreen } from "./InitialScreen";
import { NewPackage } from "./NewPackage";
import { Steps } from "antd";
import { auth, fireStore } from "../../firebase";
import { useEffect, useState } from "react";
import { PaymentForm } from "./PaymentForm";
import axios from "axios";
import { PaymentSuccess } from "./PaymentSuccess";
import { PaymentProvider, usePayment } from "./PaymentContext";
import { PaymentFail } from "./PaymentFail";

const PaymentContainer = ({ match }: any) => {
  const router = useHistory();
  const { details, setDetails } = usePayment();
  const loadDetails = async () => {
    const token = await auth.currentUser?.getIdToken();

    axios
      .post(
        "https://europe-west1-algo-senti.cloudfunctions.net/payment-detail",
        {},
        {
          headers: {
            "x-app-uid": token,
          },
        }
      )
      .then((res) => {
        fireStore
          .collection("NS_PAYMENT_SETTINGS")
          .doc("1")
          .get()
          .then((doc) => {
            const settings = doc.data();
            const detailsData = {
              ...res.data,
              settings,
            };
            setDetails(detailsData);
          });
      });
  };

  useEffect(() => {
    loadDetails();
  }, []);
  useEffect(() => {
    try {
      const newDomain = "web.sentimentalgo.net";
      setTimeout(() => {
        if (window.location?.origin.includes(newDomain)) {
          window.location.href = window.location.href.replace(
            newDomain,
            "app.sentimentalgo.com"
          );
        }
      }, 500);
    } catch (e) {
      console.error(e);
    }
  }, []);
  return (
    <div className="payment-page">
      <Switch>
        <Route
          path={`${match.url}/customer`}
          render={(props) => <CustomerForm {...props} />}
        />
        <Route
          path={`${match.url}/success`}
          render={(props) => <PaymentSuccess {...props} />}
        />
        <Route
          path={`${match.url}/complete`}
          render={(props) => <PaymentForm />}
        />
        <Route
          path={`${match.url}/fail`}
          render={(props) => <PaymentFail {...props} />}
        />
        <Route path={`${match.url}`} render={(props) => <NewPackage />} />
      </Switch>
    </div>
  );
};

const Payment = ({ match }: any) => {
  const router = useHistory();
  const [step, setStep] = useState(1);
  const onStepChange = (value: number) => {};

  return (
    <PaymentProvider>
      <PaymentContainer match={match} />
    </PaymentProvider>
  );
};

export default Payment;
