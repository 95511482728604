export const COUNTRIES = [
  {
    value: "Afganistan",
    label: "Afganistan",
  },
  {
    value: "Åland",
    label: "Åland",
  },
  {
    value: "Alderney",
    label: "Alderney",
  },
  {
    value: "Almanya",
    label: "Almanya",
  },
  {
    value: "Amerika Birleşik Devletleri",
    label: "Amerika Birleşik Devletleri",
  },
  {
    value: "Andorra",
    label: "Andorra",
  },
  {
    value: "Angola",
    label: "Angola",
  },
  {
    value: "Anguilla",
    label: "Anguilla",
  },
  {
    value: "Antarktika",
    label: "Antarktika",
  },
  {
    value: "Antigua ve Barbuda",
    label: "Antigua ve Barbuda",
  },
  {
    value: "Arjantin",
    label: "Arjantin",
  },
  {
    value: "Arnavutluk",
    label: "Arnavutluk",
  },
  {
    value: "Aruba",
    label: "Aruba",
  },
  {
    value: "Avustralya",
    label: "Avustralya",
  },
  {
    value: "Avusturya",
    label: "Avusturya",
  },
  {
    value: "Azerbaycan",
    label: "Azerbaycan",
  },
  {
    value: "Bahama",
    label: "Bahama",
  },
  {
    value: "Bahreyn",
    label: "Bahreyn",
  },
  {
    value: "Bangladeş",
    label: "Bangladeş",
  },
  {
    value: "Barbados",
    label: "Barbados",
  },
  {
    value: "Batı Sahra",
    label: "Batı Sahra",
  },
  {
    value: "Belçika",
    label: "Belçika",
  },
  {
    value: "Belize",
    label: "Belize",
  },
  {
    value: "Benin",
    label: "Benin",
  },
  {
    value: "Beyaz Rusya",
    label: "Beyaz Rusya",
  },
  {
    value: "Birleşik Arap Emirlikleri",
    label: "Birleşik Arap Emirlikleri",
  },
  {
    value: "Birleşik Krallık (Büyük Britanya ve Kuzey İrlanda)",
    label: "Birleşik Krallık (Büyük Britanya ve Kuzey İrlanda)",
  },
  {
    value: "Bolivya",
    label: "Bolivya",
  },
  {
    value: "Bosna-Hersek",
    label: "Bosna-Hersek",
  },
  {
    value: "Botsvana",
    label: "Botsvana",
  },
  {
    value: "Brezilya",
    label: "Brezilya",
  },
  {
    value: "Brunei",
    label: "Brunei",
  },
  {
    value: "Bulgaristan",
    label: "Bulgaristan",
  },
  {
    value: "Burkina Faso",
    label: "Burkina Faso",
  },
  {
    value: "Burundi",
    label: "Burundi",
  },
  {
    value: "Butan",
    label: "Butan",
  },
  {
    value: "Çad",
    label: "Çad",
  },
  {
    value: "Cape Verde",
    label: "Cape Verde",
  },
  {
    value: "Cebelitarık",
    label: "Cebelitarık",
  },
  {
    value: "Çek Cumhuriyeti",
    label: "Çek Cumhuriyeti",
  },
  {
    value: "Cezayir",
    label: "Cezayir",
  },
  {
    value: "Cibuti",
    label: "Cibuti",
  },
  {
    value: "Çin Cumhuriyeti (Tayvan)",
    label: "Çin Cumhuriyeti (Tayvan)",
  },
  {
    value: "Çin Halk Cumhuriyeti",
    label: "Çin Halk Cumhuriyeti",
  },
  {
    value: "Danimarka",
    label: "Danimarka",
  },
  {
    value: "Demokratik Kongo Cumhuriyeti",
    label: "Demokratik Kongo Cumhuriyeti",
  },
  {
    value: "Doğu Timor",
    label: "Doğu Timor",
  },
  {
    value: "Dominik Cumhuriyeti",
    label: "Dominik Cumhuriyeti",
  },
  {
    value: "Dominika",
    label: "Dominika",
  },
  {
    value: "Ekvador",
    label: "Ekvador",
  },
  {
    value: "Ekvator Ginesi",
    label: "Ekvator Ginesi",
  },
  {
    value: "El Salvador",
    label: "El Salvador",
  },
  {
    value: "Endonezya",
    label: "Endonezya",
  },
  {
    value: "Eritre",
    label: "Eritre",
  },
  {
    value: "Ermenistan",
    label: "Ermenistan",
  },
  {
    value: "Estonya",
    label: "Estonya",
  },
  {
    value: "Etiyopya",
    label: "Etiyopya",
  },
  {
    value: "Faroe Adaları",
    label: "Faroe Adaları",
  },
  {
    value: "Fas",
    label: "Fas",
  },
  {
    value: "Fiji",
    label: "Fiji",
  },
  {
    value: "Fildişi Sahili",
    label: "Fildişi Sahili",
  },
  {
    value: "Filipinler",
    label: "Filipinler",
  },
  {
    value: "Filistin",
    label: "Filistin",
  },
  {
    value: "Finlandiya",
    label: "Finlandiya",
  },
  {
    value: "Fransa",
    label: "Fransa",
  },
  {
    value: "Gabon",
    label: "Gabon",
  },
  {
    value: "Galler",
    label: "Galler",
  },
  {
    value: "Gambiya",
    label: "Gambiya",
  },
  {
    value: "Gana",
    label: "Gana",
  },
  {
    value: "Gine",
    label: "Gine",
  },
  {
    value: "Gine Bissau",
    label: "Gine Bissau",
  },
  {
    value: "Grenada",
    label: "Grenada",
  },
  {
    value: "Grönland",
    label: "Grönland",
  },
  {
    value: "Guatamala",
    label: "Guatamala",
  },
  {
    value: "Guernsey",
    label: "Guernsey",
  },
  {
    value: "Güney Afrika Cumhuriyeti",
    label: "Güney Afrika Cumhuriyeti",
  },
  {
    value: "Güney Kore",
    label: "Güney Kore",
  },
  {
    value: "Gürcistan",
    label: "Gürcistan",
  },
  {
    value: "Guyana",
    label: "Guyana",
  },
  {
    value: "Haiti",
    label: "Haiti",
  },
  {
    value: "Hindistan",
    label: "Hindistan",
  },
  {
    value: "Hırvatistan",
    label: "Hırvatistan",
  },
  {
    value: "Hollanda",
    label: "Hollanda",
  },
  {
    value: "Hollanda Antilleri",
    label: "Hollanda Antilleri",
  },
  {
    value: "Honduras",
    label: "Honduras",
  },
  {
    value: "Hong Kong",
    label: "Hong Kong",
  },
  {
    value: "İngiltere",
    label: "İngiltere",
  },
  {
    value: "Irak",
    label: "Irak",
  },
  {
    value: "İran",
    label: "İran",
  },
  {
    value: "İrlanda",
    label: "İrlanda",
  },
  {
    value: "İskoçya",
    label: "İskoçya",
  },
  {
    value: "İspanya",
    label: "İspanya",
  },
  {
    value: "İsrail",
    label: "İsrail",
  },
  {
    value: "İsveç",
    label: "İsveç",
  },
  {
    value: "İsviçre",
    label: "İsviçre",
  },
  {
    value: "İtalya",
    label: "İtalya",
  },
  {
    value: "İzlanda",
    label: "İzlanda",
  },
  {
    value: "Jamaika",
    label: "Jamaika",
  },
  {
    value: "Japonya",
    label: "Japonya",
  },
  {
    value: "Jersey",
    label: "Jersey",
  },
  {
    value: "Kamboçya",
    label: "Kamboçya",
  },
  {
    value: "Kamerun",
    label: "Kamerun",
  },
  {
    value: "Kanada",
    label: "Kanada",
  },
  {
    value: "Karadağ",
    label: "Karadağ",
  },
  {
    value: "Katar",
    label: "Katar",
  },
  {
    value: "Kazakistan",
    label: "Kazakistan",
  },
  {
    value: "Kenya",
    label: "Kenya",
  },
  {
    value: "Kiribati",
    label: "Kiribati",
  },
  {
    value: "Kıbrıs Cumhuriyeti",
    label: "Kıbrıs Cumhuriyeti",
  },
  {
    value: "Kırgızistan",
    label: "Kırgızistan",
  },
  {
    value: "Kolombiya",
    label: "Kolombiya",
  },
  {
    value: "Komor",
    label: "Komor",
  },
  {
    value: "Kongo Cumhuriyeti",
    label: "Kongo Cumhuriyeti",
  },
  {
    value: "Kosova",
    label: "Kosova",
  },
  {
    value: "Kosta Rika",
    label: "Kosta Rika",
  },
  {
    value: "Küba",
    label: "Küba",
  },
  {
    value: "Kuveyt",
    label: "Kuveyt",
  },
  {
    value: "Kuzey İrlanda",
    label: "Kuzey İrlanda",
  },
  {
    value: "Kuzey Kore",
    label: "Kuzey Kore",
  },
  {
    value: "Laos",
    label: "Laos",
  },
  {
    value: "Lesoto",
    label: "Lesoto",
  },
  {
    value: "Letonya",
    label: "Letonya",
  },
  {
    value: "Liberya",
    label: "Liberya",
  },
  {
    value: "Libya",
    label: "Libya",
  },
  {
    value: "Lihtenştayn",
    label: "Lihtenştayn",
  },
  {
    value: "Litvanya",
    label: "Litvanya",
  },
  {
    value: "Lübnan",
    label: "Lübnan",
  },
  {
    value: "Lüksemburg",
    label: "Lüksemburg",
  },
  {
    value: "Macaristan",
    label: "Macaristan",
  },
  {
    value: "Madagaskar",
    label: "Madagaskar",
  },
  {
    value: "Makao",
    label: "Makao",
  },
  {
    value: "Makedonya",
    label: "Makedonya",
  },
  {
    value: "Malavi",
    label: "Malavi",
  },
  {
    value: "Maldivler",
    label: "Maldivler",
  },
  {
    value: "Malezya",
    label: "Malezya",
  },
  {
    value: "Mali",
    label: "Mali",
  },
  {
    value: "Malta",
    label: "Malta",
  },
  {
    value: "Malta Askeri Hükümdarlığı",
    label: "Malta Askeri Hükümdarlığı",
  },
  {
    value: "Man Adası",
    label: "Man Adası",
  },
  {
    value: "Marshall Adaları",
    label: "Marshall Adaları",
  },
  {
    value: "Mauritius",
    label: "Mauritius",
  },
  {
    value: "Meksika",
    label: "Meksika",
  },
  {
    value: "Mikronezya",
    label: "Mikronezya",
  },
  {
    value: "Mısır",
    label: "Mısır",
  },
  {
    value: "Moğolistan",
    label: "Moğolistan",
  },
  {
    value: "Moldova",
    label: "Moldova",
  },
  {
    value: "Monako",
    label: "Monako",
  },
  {
    value: "Moritanya",
    label: "Moritanya",
  },
  {
    value: "Mozambik",
    label: "Mozambik",
  },
  {
    value: "Myanmar",
    label: "Myanmar",
  },
  {
    value: "Namibya",
    label: "Namibya",
  },
  {
    value: "Nauru",
    label: "Nauru",
  },
  {
    value: "Nepal",
    label: "Nepal",
  },
  {
    value: "Nijer",
    label: "Nijer",
  },
  {
    value: "Nijerya",
    label: "Nijerya",
  },
  {
    value: "Nikaragua",
    label: "Nikaragua",
  },
  {
    value: "Norveç",
    label: "Norveç",
  },
  {
    value: "Orta Afrika Cumhuriyeti",
    label: "Orta Afrika Cumhuriyeti",
  },
  {
    value: "Özbekistan",
    label: "Özbekistan",
  },
  {
    value: "Pakistan",
    label: "Pakistan",
  },
  {
    value: "Palau",
    label: "Palau",
  },
  {
    value: "Panama",
    label: "Panama",
  },
  {
    value: "Papua Yeni Gine",
    label: "Papua Yeni Gine",
  },
  {
    value: "Paraguay",
    label: "Paraguay",
  },
  {
    value: "Peru",
    label: "Peru",
  },
  {
    value: "Polonya",
    label: "Polonya",
  },
  {
    value: "Portekiz",
    label: "Portekiz",
  },
  {
    value: "Porto Riko",
    label: "Porto Riko",
  },
  {
    value: "Romanya",
    label: "Romanya",
  },
  {
    value: "Ruanda",
    label: "Ruanda",
  },
  {
    value: "Rusya",
    label: "Rusya",
  },
  {
    value: "Saint Kitts ve Nevis",
    label: "Saint Kitts ve Nevis",
  },
  {
    value: "Saint Lucia",
    label: "Saint Lucia",
  },
  {
    value: "Saint Vincent ve Grenadines",
    label: "Saint Vincent ve Grenadines",
  },
  {
    value: "Samoa",
    label: "Samoa",
  },
  {
    value: "San Marino",
    label: "San Marino",
  },
  {
    value: "Sao Tome ve Principe",
    label: "Sao Tome ve Principe",
  },
  {
    value: "Senegal",
    label: "Senegal",
  },
  {
    value: "Seyşeller",
    label: "Seyşeller",
  },
  {
    value: "Sierra Leone",
    label: "Sierra Leone",
  },
  {
    value: "Şili",
    label: "Şili",
  },
  {
    value: "Singapur",
    label: "Singapur",
  },
  {
    value: "Sırbistan",
    label: "Sırbistan",
  },
  {
    value: "Slovakya",
    label: "Slovakya",
  },
  {
    value: "Slovenya",
    label: "Slovenya",
  },
  {
    value: "Solomon Adaları",
    label: "Solomon Adaları",
  },
  {
    value: "Somali",
    label: "Somali",
  },
  {
    value: "Sri Lanka",
    label: "Sri Lanka",
  },
  {
    value: "Sudan",
    label: "Sudan",
  },
  {
    value: "Surinam",
    label: "Surinam",
  },
  {
    value: "Suriye",
    label: "Suriye",
  },
  {
    value: "Suudi Arabistan",
    label: "Suudi Arabistan",
  },
  {
    value: "Svaziland",
    label: "Svaziland",
  },
  {
    value: "Tacikistan",
    label: "Tacikistan",
  },
  {
    value: "Tanzanya",
    label: "Tanzanya",
  },
  {
    value: "Tayland",
    label: "Tayland",
  },
  {
    value: "Togo",
    label: "Togo",
  },
  {
    value: "Tonga",
    label: "Tonga",
  },
  {
    value: "Transdinyester",
    label: "Transdinyester",
  },
  {
    value: "Tunus",
    label: "Tunus",
  },
  {
    value: "Türkiye",
    label: "Türkiye",
  },
  {
    value: "Türkmenistan",
    label: "Türkmenistan",
  },
  {
    value: "Tuvalu",
    label: "Tuvalu",
  },
  {
    value: "Uganda",
    label: "Uganda",
  },
  {
    value: "Ukrayna",
    label: "Ukrayna",
  },
  {
    value: "Umman",
    label: "Umman",
  },
  {
    value: "Ürdün",
    label: "Ürdün",
  },
  {
    value: "Uruguay",
    label: "Uruguay",
  },
  {
    value: "Vanuatu",
    label: "Vanuatu",
  },
  {
    value: "Vatikan",
    label: "Vatikan",
  },
  {
    value: "Venezuela",
    label: "Venezuela",
  },
  {
    value: "Vietnam",
    label: "Vietnam",
  },
  {
    value: "Yemen",
    label: "Yemen",
  },
  {
    value: "Yeni Kaledonya",
    label: "Yeni Kaledonya",
  },
  {
    value: "Yeni Zelanda",
    label: "Yeni Zelanda",
  },
  {
    value: "Yunanistan",
    label: "Yunanistan",
  },
  {
    value: "Zambiya",
    label: "Zambiya",
  },
  {
    value: "Zimbabve",
    label: "Zimbabve",
  },
];
