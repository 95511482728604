import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const COLLECTION_NAME = "NS_HISTORY_LINES";

interface IXUData {
  data: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  data: [],
  requested: false,
  loaded: false,
};

export function loadLinesHistory(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.LinesHistory.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.LINES_HISTORY,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}`)
      .orderBy("symbol", "asc")
      .onSnapshot((querySnapshot: any) => {
        var data: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          data.push({
            Symbol: doc.data().symbol,
            Index: doc.data().index,
            SentimentList: doc.data().sentimentList,
            PriceList: doc.data().priceList,
            ChangeList: doc.data().changeList,
            DateList: doc.data().dateList,
            STP: doc.data().stpList,
            MTP: doc.data().mtpList,
            OrderList: doc.data().orderList,
          });
        });
        dispatch({
          type: SENTIMENT_ACTION.LINES_HISTORY,
          payload: {
            data: data,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function LinesHistory(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.LINES_HISTORY:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const linesHistorySelector = (state: any) => state.LinesHistory;
