import antdAR from "antd/lib/locale-provider/az_AZ";
import azMessages from "../locales/az_AZ.json";

const azLang = {
  messages: {
    ...azMessages
  },
  antd: antdAR,
  locale: 'az',
};
export default azLang;
