import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";

const DATA_COUNT = 100;
const COLLECTION_NAME = "NS_SENTIMENT_POWER";

interface ISentimentPowerData {
  power: IData[];
  requested: boolean;
  loaded: boolean;
}

const initialState: ISentimentPowerData = {
  power: [],
  requested: false,
  loaded: false,
};

export function loadSentimentPower(firebase: any) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.SentimentPower.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.SENTIMENT_POWER,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .onSnapshot((querySnapshot: any) => {
        var powerData: Object[] = [];

        querySnapshot.forEach(function (doc: any) {
            powerData.push({
            name: "power",
            value: doc.data().value,
            dateTime: doc.data().dateTime.toDate(),
          });
        });


        dispatch({
          type: SENTIMENT_ACTION.SENTIMENT_POWER,
          payload: {
            power: powerData,
            loaded: true,
            requested: true,
          },
        });
      });
  };
}

export default function SentimentPower(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.SENTIMENT_POWER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const sentimentPowerSelector = (state: any) => state.SentimentPower;
